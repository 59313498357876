import styled from "styled-components";
import {
  contextObjforCloseWizardReconcileDisb,
  contextObjforNextWizardReconcile,
  contextObjReconcileDisbuForPrepareRepayment,
  objectforBeneval,
  objectforOPSPrepareDisb,
  objectforRealfundsTransfer,
  objectforsetrepaymentdetails,
} from "../../Common/Constants";
import React, { useState } from "react";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { controllerAPI, opscontroller } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { useNavigate } from "react-router-dom";

const Step6 = ({
  step1output,
  step5output,
  handleSubmit,
  handleChange,
  formData,
  setstep6output,

  wizardRecordId,
  wizardRecordInstanceId,
  setWizardRecordInstanceId,
  wizardPageId,
  setWizardPageId,
  actionButtonId,
  setActionButtonId,
  NextButtonId,
  setNextButtonId,
}) => {
  const [btnenable, setbtnenable] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showtable, setshowtable] = useState(false);
  const [data, setdata] = useState([]);
  const [showdata, setshowdata] = useState(false);
  const [closeId, setCloseId] = useState(1);
  const [wizardRecordInstanceIdStep1, setwizardRecordInstanceIdStep1] =
    useState(wizardRecordInstanceId);
  const [val, setVal] = useState();
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  const navigate = useNavigate();

  const ActionButtonResponse = async (id) => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjReconcileDisbuForPrepareRepayment(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceId,
        wizardPageId,
        actionButtonId,
        id
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `reconcile-disbursement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }
      if (ATTradesresponse?.response?.status === 500) {
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.message);
        setAlertSeverity("error");
        console.log(
          "ATTradesresponse for 500 ",
          ATTradesresponse?.response?.status
        );
      }
      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setActionButtonId(actionButtonId + 1);
        setwizardRecordInstanceIdStep1(
          ATTradesresponse?.res?.data?.data?.reconcileDisbursementWizardStepData
            ?.id
        );
        setVal(
          ATTradesresponse?.res?.data?.data?.reconcileDisbursementWizardStepData
            ?.id
        );
        setOpenSnackbar(true);
        setApiResultMessage("Repayment Details Created Successfully!");
        setAlertSeverity("success");
        setshowdata(true);
        try {
          const parseMsg = JSON.parse(
            ATTradesresponse?.res?.data?.data
              ?.reconcileDisbursementWizardStepData?.apiResponse
          );
          const parsTwo = JSON.parse(parseMsg);
          console.log("api response data is:", parsTwo);
          setstep6output(parsTwo?.data);
          setdata(parsTwo?.data);
        } catch (error) {
          console.error("Failed to parse API response:", error);
        }
        setbtnenable(true);
        setshowtable(true);
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      if (error?.response?.status === 500) {
        setOpenSnackbar(true);
        setApiResultMessage(
          error?.response?.data?.message || "Error in setting Repayment"
        );
        setAlertSeverity("error");
      }
    } finally {
      setLoading(false);
    }
  };

  const closBtn = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforCloseWizardReconcileDisb(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceIdStep1,
        closeId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `reconcile-disbursement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        window.location.reload();
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Reconcile Disbursement!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const setRepayment = async (id) => {
    const constructObj = objectforsetrepaymentdetails(id);
    try {
      setLoading(true);
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "set-repayment-details"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Repayment Details Created Successfully!");
        setAlertSeverity("success");
        setstep6output(response?.res?.data?.data);
        setbtnenable(true);
        setshowtable(true);
        setdata(response?.res?.data?.data);
        window.location.reload();
      }
      if (response?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          response?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error while Creating Repayment Record:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error while Creating Repayment Record !");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  console.log("the data to be shown is :", data);
  return (
    <StepContainer>
      <div className="step-content">
        <h2>Create Repayment</h2>
        <p>
          Click below to create a Repayment record For Finance Request Id:{"  "}
          <span style={{ fontWeight: "bold" }}>
            {step1output?.financeRequest?.id}
          </span>
        </p>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "400px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                disabled={btnenable}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  height: "fit-content",
                  boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                  margin: "5px",
                  opacity: !btnenable ? "1" : "0.5",
                }}
                onClick={() => {
                  ActionButtonResponse(step5output?.disbursement?.id);
                }}
              >
                Proceed
              </Button>
            </div>{" "}
            {showtable === true ? (
              <>
                <table style={{ marginTop: "10px" }}>
                  <thead>
                    <tr>
                      <th>Repayment Id</th>
                      <th>Repayment Reference Number</th>
                      <th>Repayment Status</th>
                      <th>Total Repayment Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{data?.repayment?.id}</td>
                      <td>{data?.repayment?.repaymentRefNo}</td>
                      <td>{data?.repayment?.repaymentStatus}</td>
                      <td>{data?.repayment?.totalRepaymentAmount}</td>
                    </tr>
                  </tbody>
                </table>
                {/*<div
                  style={{
                    margin: "30px 0px",
                    display: "flex",
                    flexDirection: "column",
                    width: "400px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "#4A3AFF",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                      height: "fit-content",
                      boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                      margin: "5px",
                      opacity: btnenable ? "1" : "0.5",
                      padding: "15px 30px",
                      fontSize: "15px",
                    }}
                  >
                    Close Wizard
                  </button>
                </div>*/}
              </>
            ) : (
              <></>
            )}
            <>
              {showdata === true ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "400px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Button
                      style={{
                        borderRadius: "5px",
                        color: "black",
                        height: "45px",
                        width: "130px",
                        backgroundColor: "white",
                        border: " 1px #404040 solid",
                        cursor: "pointer",
                        height: "fit-content",
                        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                        margin: "5px",
                        opacity: "1",
                      }}
                      onClick={() => {
                        closBtn();
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <p></p>
                </>
              )}
            </>
          </>
        )}

        <Snackbar
          open={openSnackbar}
          //autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {apiResultMessage}
          </Alert>
        </Snackbar>
      </div>
    </StepContainer>
  );
};

export default Step6;

const StepContainer = styled.div`
  .step-content {
    max-width: 600px;
    margin: auto;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
