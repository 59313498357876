// src/components/GSTDashboard.jsx
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BigNumberWidget from './widgets/BigNumberWidget';
import DonutChartWidget from './widgets/DonutChartWidget';
import MixedChartWidget from './widgets/MixedChartWidget';
import { useAuth } from "../../context/auth";
import TableWidget from './widgets/TableWidget';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { analyticsDashboardObject, contextObjController } from '../Common/Constants';
import { controllerAPI } from '../API/FP/Actions';
import { Navigate } from 'react-router-dom';

const GSTDashboard = ({setActiveIndex}) => {
  const apiResponse = useSelector((state) => state.apiResponse.data);
  console.log("FROM the REDUX component :", apiResponse?.payload?.[0]?.gstId  );
  const gstId = apiResponse?.payload?.[0]?.gstId;
  const NewGstId = `'${gstId}'`;
  console.log("srikar this is :", NewGstId)
  const [ loading, setIsLoading] =useState(false);
  const [auth, setAuth] = useAuth();
  const [atDetals, setAtDetails]= useState(); 
  const [ dataForDashboard, setDataForDashboard] = useState()
  const [dataForTableTwo, setDataForTableTwo] = useState()
  const [pieChartDataa,setPieChartData] =useState()
  const [barChartData , setBarChartData ] = useState()
  const [pieChartDataTwo ,setPieChartDataTwo ] = useState();
  const [pieChartThree, setPieChartThreeData] = useState();
  const [totalTableOne, setTOtalTableOne] = useState();
  const [totalTableTwo, setTOtalTableTwo] = useState();
  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const analyticsApi = async() =>{
    setIsLoading(true);

    try{
      const constructObjD = contextObjController(authContextObj, anchorTraderID);
            const profileDetailsResponse = await controllerAPI(
              "post",
              auth?.token,
              constructObjD,
              "get-at-profile-details"
            );
      
      if (profileDetailsResponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        Navigate("/creditbazaar/");
      }
      if (profileDetailsResponse.res.status === 200) {
        const gstId = `'${profileDetailsResponse?.res?.data?.data?.gstinList?.[0]?.gstId}'`
        const constructObj = analyticsDashboardObject(gstId);
        const AtAnalyticsResponse = await controllerAPI(
          "post",
          //auth?.token,
         "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwcm9maW50ZWNoX2FkbWluIiwiaWF0IjoxNzEyOTIwODQzLCJleHAiOjE3NDQ0Nzc3OTUsInR5cGUiOiJBUFBMSUNBVElPTiIsInRlbmFudC1pZCI6InByb2ZpbnRlY2giLCJzZXJ2aWNlLW5hbWUiOiJjcnlwdG8tdXRpbHMifQ.8HtXV8tkUWxvTNbRGyuguorjTzrhm-7l4tMIiwjUg7C-XhiMHypA77f7WccedM8Y2sxcwFe-FIxVeeukrSpyiw",
          constructObj,
          `analytics`
        )
        if (AtAnalyticsResponse?.res?.status === 401) {
          setAuth({
            ...auth,
            user: null,
            token: "",
            userDisplayName: "",
            userDetails: null,
          });
          localStorage.removeItem("auth");
          Navigate("/creditbazaar/");
        }
        if (AtAnalyticsResponse?.res?.status === 200) { 
        console.log("AtAnalyticsResponse is ",AtAnalyticsResponse) 
          AtAnalyticsResponse?.res?.data.map((i,index)=>{if(i?.AT_Name != null){
            return setAtDetails(i)
          }})
          setDataForDashboard(AtAnalyticsResponse?.res?.data) 
          const barchart = AtAnalyticsResponse?.res?.data.filter(i=>i?.Total_Purchases_SalesandPurchases!= null)
          const pieChartOne =  AtAnalyticsResponse?.res?.data?.filter(i=>i?.total_amount_SalesbyBuyersRegion!=null)
          const pieChartTwo = AtAnalyticsResponse?.res?.data?.filter(i=>i?.total_amount_TopCustomerbyAmount!=null)
          const pieThree = AtAnalyticsResponse?.res?.data?.filter(i=>i?.total_amount_TopProcurement!=null)
          const totalTableOne = AtAnalyticsResponse?.res?.data?.filter(i=>i?.Total_Sales_table1!=null)
          const totalTableTwo = AtAnalyticsResponse?.res?.data?.filter(i=>i?.Total_Sales_table2!=null)
          setTOtalTableOne(totalTableOne)
          setTOtalTableTwo(totalTableTwo)
          setPieChartThreeData(pieThree)
          setPieChartDataTwo(pieChartTwo)
          setPieChartData(pieChartOne)
          setBarChartData(barchart)
          console.log("Manoj says he is great !:",AtAnalyticsResponse?.res?.data);
        }
        if(AtAnalyticsResponse?.res?.status === 400){
          console.log("error is :", AtAnalyticsResponse)
        }
        
      }
      
     

    }
    catch(err){
      console.log("error is :",err)
    }finally {
      setIsLoading(false);
    }
  }

  console.log("the At details are ",atDetals)

  useEffect(()=>{
    analyticsApi()
  }, [ ])

  setActiveIndex(4)
  // Data for big number widgets
  const regionColors = ['#FF9F40', '#A78BFA', '#8B7355', '#D3D3D3', '#FF6B6B'];
  const customerColors = ['#FF6B6B', '#4FD1C5', '#A78BFA', '#63B3ED', '#F6AD55', '#68D391', '#FC8181', '#B794F4', '#66DAE3', '#7F9CF5'];


  const topCompaniesColumnsTable1 = [
    { key: 'column1', label: 'gstr1_2000' },
    { key: 'column2', label: 'Total Sales ₹' }
  ];



  
  return (
    <DashboardContainer>
      {loading == true? (<>
      
        <CircularProgress
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            zIndex: "1100",
          }}
        />
      </>):(<>
        <DashboardSection>
        <DashboardTitle>GST Business Analysis</DashboardTitle>
        
        {/* Anchor Trader Details */}
        <CardContainer>
          <SectionTitle>Anchor Trader Details</SectionTitle>
          <TableWidget
            columns={[
              { key: 'name', label: 'AT Name' },
              { key: 'gst', label: 'GST ID' },
              { key: 'date', label: 'Last Updated Date' },
              { key: 'sales', label: 'Total Sales' }
            ]}
            data={[
              {
                name: atDetals?.AT_Name,
                gst: atDetals?.GST_ID,
                date: atDetals?.Last_Updated_Date,
                sales: atDetals?.Total_Sales_AT_Detail
              }
            ]}
            column1={null} 
            column2 = {null}
          />
        </CardContainer>
        {/* Summary Numbers */}
        <SummaryGrid>
        {dataForDashboard?.map((i,index)=>{if(i?.Total_B2B_Sales!= null){
            return <BigNumberWidget 
            number={`₹ ${parseFloat(i?.Total_B2B_Sales).toFixed(2)}`} 
            label="Total B2B Sales" 
            color="#4F46E5" 
          />
          
          }})}
          {dataForDashboard?.map((i,index)=>{if(i?.Total_B2C_Sales!= null){
            return <BigNumberWidget number={`₹ ${parseFloat(i?.Total_B2C_Sales).toFixed(2)}`} label="Total B2C Sales" color="#4F46E5"/>
          }})}
             {dataForDashboard?.map((i,index)=>{if(i?.Total_Purchases!= null){
            return <BigNumberWidget number={`₹ ${parseFloat(i?.Total_Purchases).toFixed(2)}`}label="Total Purchases" color="#4F46E5"   />
          }})}

{dataForDashboard?.map((i,index)=>{if(i?.Unique_Customers!= null){
            return <BigNumberWidget number={`₹ ${i?.Unique_Customers}`} label="Unique Customers (B2B)" color="#4F46E5" />
          }})}
        </SummaryGrid>

        {/* Charts Row */}
        {console.log("barChartData", barChartData)}
        <ChartsGrid>
          <MixedChartWidget data={barChartData} />
          <DonutChartWidget
            data={pieChartDataa}
            nami="total_amount_SalesbyBuyersRegion"
            validation= "tp_stcd"
            dataKey="value"
            nameKey="name"
            colors={regionColors}
            title="Total Sales by Buyers Region (B2B)"
          />
        </ChartsGrid>

        {/* Bottom Charts */}
        <ChartsGrid>
          <DonutChartWidget
            data={pieChartDataTwo}
             nami="total_amount_TopCustomerbyAmount"
            validation= "tp_tradenam"
            dataKey="value"
            nameKey="name"
            colors={customerColors}
            title="Top Customer by Amount (B2B)"
          />
          <DonutChartWidget
            data={pieChartThree}
             nami="total_amount_TopProcurement"
            validation= "tp_tradenam"
            dataKey="value"
            nameKey="name"
            colors={customerColors}
            title="Top Procurement (B2B)"
          />
        </ChartsGrid>

        {/* Bottom Tables */}
        <ChartsGrid>
          <TableSection>
            <SectionTitle>Trade Partners(Customers) in Top 2000 Companies</SectionTitle>
            <TableWidget columns={topCompaniesColumnsTable1} data={totalTableOne} column1={"Total_Sales_table1"} column2={"gstr1_2000"} />
          </TableSection>
          <TableSection>
            <SectionTitle>Trade Partners(Procurement's) in Top 2000 Companies</SectionTitle>
            <TableWidget columns={topCompaniesColumnsTable1} data={totalTableTwo} column1={"Total_Sales_table2"} column2={"gstr2_2000"}/>
          </TableSection>
        </ChartsGrid>
      </DashboardSection>
      </>)}
      
    </DashboardContainer>
  );
};

// Styled Components
const DashboardContainer = styled.div`
  padding: 1.5rem;
  background-color: #F9FAFB;
  min-height: 100vh;
`;

const DashboardSection = styled.div`
  margin-bottom: 1.5rem;
`;

const DashboardTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 1.5rem;
`;

const CardContainer = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.125rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 1rem;
`;

const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TableSection = styled(CardContainer)`
  margin-bottom: 0;
`;

export default GSTDashboard;