import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../../../context/auth";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Typography,
  Switch,
  FormControlLabel,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { controlerObjectForUploadIndividual } from "../../Common/Constants";
import { controllerAPIForROCrtn } from "../../API/FP/Actions";

const Step2 = ({ nextStep }) => {
  const data = useSelector((state) => state.IndividualAssessmentReducer.data);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [auth, setAuth] = useAuth();
  const [individualAssessmentList, setIndividualAssessmentList] = useState([
    data,
  ]);
  const [formData, setFormData] = useState(
    data.map((item) => ({
      id: item.id,
      grnPresent: false,
      einvoicePresent: false,
      ewayBillPresent: false,
      tradePartnerConfirmation: "NOT_REVIEWED",
    }))
  );
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const handleInputChange = (id, field, value) => {
    setFormData((prevFormData) =>
      prevFormData.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleUpdateIndividualAssessments = async () => {
    console.log("Setting loading to true");
    setLoading(true);

    const updatedData = data.map((item) => {
      const form = formData.find((f) => f.id === item.id);
      return { ...item, ...form };
    });

    console.log("Updated Data:", updatedData);

    try {
      const controllerObject = controlerObjectForUploadIndividual(
        authContextObj,
        updatedData
      );
      const apiResponse = await controllerAPIForROCrtn(
        "post",
        auth?.token,
        controllerObject,
        "update-individual-assessments"
      );
      console.log("API response received:", apiResponse);

      if (apiResponse?.res?.status === 200) {
        console.log("Data updated successfully");
        setApiResultMessage("The Individual list data updated successfully");
        setOpenSnackbar(true);
        setAlertSeverity("success");
        nextStep();
      } else {
        console.warn("API returned status 400");
        setApiResultMessage("The Individual list data is not updated ");
        setOpenSnackbar(true);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("API call failed with error:", error);
      setApiResultMessage("The Individual list data is not updated ");
      setOpenSnackbar(true);
      setAlertSeverity("error");
    } finally {
      console.log("Setting loading to false");
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div>
            <Box sx={{ padding: "16px" }}>
              {data.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    marginBottom: "24px",
                    padding: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Update the details for Individual assessments id: {item.id}
                    <br />
                    <h4>
                      {" "}
                      Invoice Id : {item?.invoiceId}, <br />
                      Invoice Amount : {item?.invoiceAmount}
                    </h4>
                  </Typography>

                  {/* GRN Present */}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          formData.find((form) => form.id === item.id)
                            .grnPresent
                        }
                        onChange={(e) =>
                          handleInputChange(
                            item.id,
                            "grnPresent",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="GRN Present"
                  />

                  {/* E-Invoice Present */}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          formData.find((form) => form.id === item.id)
                            .einvoicePresent
                        }
                        onChange={(e) =>
                          handleInputChange(
                            item.id,
                            "einvoicePresent",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="E-Invoice Present"
                  />

                  {/* E-Way Bill Present */}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          formData.find((form) => form.id === item.id)
                            .ewayBillPresent
                        }
                        onChange={(e) =>
                          handleInputChange(
                            item.id,
                            "ewayBillPresent",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="E-Way Bill Present"
                  />

                  {/* Trade Partner Confirmation */}
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Trade Partner Confirmation</InputLabel>
                    <Select
                      value={
                        formData.find((form) => form.id === item.id)
                          ?.tradePartnerConfirmation || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          item.id,
                          "tradePartnerConfirmation",
                          e.target.value
                        )
                      }
                      label="Trade Partner Confirmation"
                      id="TradePartnerConfirmation"
                    >
                      <MenuItem value="APPROVED">Approved</MenuItem>
                      <MenuItem value="REJECTED">Rejected</MenuItem>
                      <MenuItem value="NOT_REVIEWED">Not Reviewed</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              ))}

              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateIndividualAssessments}
                sx={{ marginTop: "16px" }}
              >
                Update
              </Button>
            </Box>
          </div>
          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default Step2;
