import { createSlice } from "@reduxjs/toolkit";

const SettlementWizardSlice = createSlice({
  name: "settlementWizard Slice ",

  initialState: {
    participantSettlementId: 0,
  },
  reducers: {
    getSettlementID(state, action) {
      state.participantSettlementId = action.payload;
    },
  },
});

export const { getSettlementID } = SettlementWizardSlice.actions;
export default SettlementWizardSlice.reducer;
