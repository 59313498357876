import { Alert, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth";
import styled from "styled-components";
import {
  CompleteSettlement,
  contextObjforNextWizardReconcile,
  contextObjforNextWizardSettlement,
  PreparetriggerSettlementBeneValidation,
} from "../../Common/Constants";
import { controllerAPI, opscontroller } from "../../API/FP/Actions";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSettlementID } from "./SettlementWizardSlice";
const Step1 = ({
  selectedRecordDetails,
  nextStep,
  setstep1output,
  handleChange,
  formData,
  requiredMaps,
  setsecondStep1Output,
  selectedParticipantSettlement,

  wizardRecordId,
  wizardRecordInstanceId,
  setWizardRecordInstanceId,
  wizardPageId,
  setWizardPageId,
  NextButtonId,
  setNextButtonId,
}) => {
  const handleInputChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };
  const [btnenable, setbtnenable] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showdata, setshowdata] = useState(false);
  const [data, setdata] = useState([]);
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const [partcicipantDetails, setParticipantDetails] = useState([
    selectedParticipantSettlement,
  ]);
  dispatch(getSettlementID(partcicipantDetails?.[0]?.id));

  console.log("chcecking now", partcicipantDetails?.[0]?.id);
  setstep1output(partcicipantDetails?.[0]);
  console.log("selectedParticipantSettlement", selectedParticipantSettlement);
  console.log("participantSettlement is :");
  console.log("beneValidationS3Path is :");
  const [settlementData, setSettelementData] = useState([]);
  console.log(
    "the console log is from step1 step1output is :",
    partcicipantDetails?.[0]
  );
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  console.log(
    "contextObjforNextWizardSettlement from srikar is :",
    wizardRecordInstanceId
  );
  const NextButton = async (id) => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforNextWizardSettlement(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceId,
        wizardPageId,
        NextButtonId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `settlement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        Navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setNextButtonId(NextButtonId + 1);
        setWizardRecordInstanceId(
          ATTradesresponse?.res?.data?.data?.executeSettlementWizardStepData?.id
        );

        setWizardPageId(wizardPageId + 1);
        nextStep();
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Reconcile Disbursement!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row", gap: "50%" }}>
        <div className="step-content">
          <h2>Step 1 : Participant Settlement Details</h2>
          <h3> Check the below Participant Settlement details :</h3>

          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableContainer>
              <div className="data-grid">
                {partcicipantDetails?.map((row, index) => {
                  const completedCount = partcicipantDetails
                    .slice(0, index)
                    .filter(
                      (item) => item.participantSettlementStatus === "PSCD"
                    ).length;

                  const shouldTriggerOutput =
                    completedCount === 2 &&
                    row.participantSettlementStatus === "PSCR";

                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <form key={index} className="participant-form">
                        <div className="form-row">
                          <label>ID</label>
                          <div>{row?.id}</div>
                        </div>
                        <div className="form-row">
                          <label>Participant Id</label>
                          <div>{row?.participantId}</div>
                        </div>
                        <div className="form-row">
                          <label>Participant Name</label>
                          <div>{row?.participantName}</div>
                        </div>
                        <div className="form-row">
                          <label>Gst Id</label>
                          <div>{row?.gstId}</div>
                        </div>
                        <div className="form-row">
                          <label>Settlement Type</label>
                          <div>{row?.settlementType}</div>
                        </div>
                        <div className="form-row">
                          <label>Charge Type</label>
                          <div>{row?.chargeType}</div>
                        </div>
                        <div className="form-row">
                          <label>Settlement Amount</label>
                          <div>{row?.settlementAmount}</div>
                        </div>
                        <div className="form-row">
                          <label>Settlement Due Date</label>
                          <div>{row?.settlementDueDate}</div>
                        </div>
                        <div className="form-row">
                          <label>Patron Of Payment</label>
                          <div>{row?.patronOfPayment}</div>
                        </div>
                        <div className="form-row">
                          <label>Recipient Of Payment</label>
                          <div>{row?.recipientOfPayment}</div>
                        </div>
                        <div className="form-row">
                          <label>Participant Settlement Status</label>
                          <div>{row?.participantSettlementStatus}</div>
                        </div>
                      </form>
                    </div>
                  );
                })}
              </div>
            </TableContainer>
          )}
        </div>

        <button
          style={{
            borderRadius: "10px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            margin: "5px",
            opacity: "1",
            padding: "15px 30px",
            fontSize: "15px",
          }}
          onClick={() => {
            NextButton();
          }}
          className="button"
        >
          Next
        </button>
      </div>
    </StepContainer>
  );
};

export default Step1;

const StepContainer = styled.div`
  .data-grid {
    display: flex;
    flex-direction: column;
  }

  .participant-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 20px;
  }

  .form-row {
    display: contents; /* Makes sure each row has 2 columns */
  }

  label {
    font-weight: bold;
  }

  button {
    grid-column: span 2; /* Makes the button take the full width */
  }

  .step-content {
    max-width: 960px;
    margin: 0px 30px;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;

const TableContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
`;
