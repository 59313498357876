import React, { useState } from "react";
import "../DirectParticipantTransaction/DirectParticipantTransactionWizard.css";
import { ContextObjForDirectParticipantTransaction } from "../../Common/Constants";
import { callAPIDirectParticipantTransaction } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

const DirectParticipantTransactionWizard = () => {
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showBtn, setShowBtn] = useState(true);
  const [auth, setAuth] = useAuth();
  const initialFormData = {
    sourceAccountName: "",
    sourceAccountNumber: "",
    sourceAccountIFSCCode: "",
    destinationAccountName: "",
    destinationAccountNumber: "",
    destinationAccountIFSCCode: "",
    transactionDateTime: "",
    transactionAmount: "",
    transactionDescription: "",
    transactionId: "",
    transactionReferenceNumber: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const convertToUTC = (datetime) => {
      if (datetime) {
        const date = new Date(datetime);
        const isoString = date.toISOString();
        return isoString.slice(0, 19) + "Z";
      }
      return null;
    };
    const transDateTime = convertToUTC(formData.transactionDateTime);
    const updatedFormData = {
      ...formData,
      transactionDateTime: transDateTime,
    };
    const controllerObj =
      ContextObjForDirectParticipantTransaction(updatedFormData);
    console.log(controllerObj);
    try {
      const apiResponse = await callAPIDirectParticipantTransaction(
        auth?.token,
        controllerObj,
        `create-direct-participant-transaction-details`
      );
      if (apiResponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }
      if (apiResponse?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Saved Details Successfully");
        setAlertSeverity("success");
        setShowBtn(false);
        setTimeout(window.location.reload(), 7000);
      }
      if (apiResponse?.response?.status === 400) {
        setOpenSnackbar(true);
        setApiResultMessage(
          "Oops! It looks like some fields are empty. Please complete all required information to proceed."
        );
        setAlertSeverity("error");
      }
    } catch (error) {
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured while saving");
      setAlertSeverity("error");
      console.error("Error during API call:", error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData(initialFormData);
  };

  return (
    <div className="parent">
      <div className="content">
        <p>Direct Participant Transaction Details</p>
        <p>Enter details for direct transactions to the pooling account.</p>
      </div>
      <form onSubmit={handleSubmit}>
        <p className="background">Source account information</p>
        <div className="flex">
          <p className="labelText">
            Source Account Name
            <input
              placeholder="Enter Account Name"
              className="styled-input-medium"
              type="text"
              name="sourceAccountName"
              value={formData.sourceAccountName}
              onChange={handleChange}
              required
            />
          </p>
          <p className="labelText">
            Source Account Number
            <input
              placeholder="Enter Account Number"
              type="text"
              className="styled-input-medium"
              name="sourceAccountNumber"
              value={formData.sourceAccountNumber}
              onChange={handleChange}
              required
            />
          </p>
          <p className="labelText">
            Source Account IFSC Code
            <input
              placeholder="Enter IFSC Code"
              type="text"
              className="styled-input-medium"
              name="sourceAccountIFSCCode"
              value={formData.sourceAccountIFSCCode}
              onChange={handleChange}
              required
            />
          </p>
        </div>
        <p className="background">Destination account information</p>
        <div className="flex">
          <p className="labelText">
            Destination Account Name
            <input
              placeholder="Enter Account Name"
              type="text"
              className="styled-input-medium"
              name="destinationAccountName"
              value={formData.destinationAccountName}
              onChange={handleChange}
              required
            />
          </p>
          <p className="labelText">
            Destination Account Number
            <input
              placeholder="Enter Account Number"
              type="text"
              className="styled-input-medium"
              name="destinationAccountNumber"
              value={formData.destinationAccountNumber}
              onChange={handleChange}
              required
            />
          </p>
          <p className="labelText">
            Destination Account IFSC Code
            <input
              placeholder="Enter IFSC Code"
              type="text"
              className="styled-input-medium"
              name="destinationAccountIFSCCode"
              value={formData.destinationAccountIFSCCode}
              onChange={handleChange}
              required
            />
          </p>
        </div>
        <p className="background">Transaction information</p>
        <div className="flex">
          <p className="labelText">
            Transaction ID
            <input
              placeholder="Enter Transaction ID"
              type="text"
              className="styled-input-medium"
              name="transactionId"
              value={formData.transactionId}
              onChange={handleChange}
              required
            />
          </p>
          <p className="labelText">
            Transaction Amount
            <input
              placeholder="Enter Transaction Amount"
              type="number"
              className="styled-input-medium"
              step="0.01"
              name="transactionAmount"
              value={formData.transactionAmount}
              onChange={handleChange}
              required
            />
          </p>
          <p className="labelText">
            Transaction DateTime
            <input
              placeholder="Select Transaction Date and Time"
              type="datetime-local"
              className="styled-input-medium"
              name="transactionDateTime"
              value={formData.transactionDateTime}
              onChange={handleChange}
              required
            />
          </p>
        </div>
        <div className="flex">
          <p className="labelText">
            Transaction Description
            <br></br>
            <input
              type="text"
              placeholder="Enter Description"
              className="styled-input-medium"
              name="transactionDescription"
              value={formData.transactionDescription}
              onChange={handleChange}
              required
            />
          </p>
          <p className="labelText">
            Transaction Reference Number
            <input
              placeholder="Enter Transaction Reference Number"
              className="styled-input-large"
              type="text"
              name="transactionReferenceNumber"
              value={formData.transactionReferenceNumber}
              onChange={handleChange}
              required
            />
          </p>
        </div>
        <div className="btn-container">
          <button
            type="submit"
            className="submit-btn"
            disabled={!showBtn}
            style={{ opacity: showBtn ? "1" : "0.5" }}
          >
            Submit
          </button>
          <button className="reset-btn" onClick={handleReset}>
            Reset
          </button>
        </div>
      </form>
      <Snackbar
        open={openSnackbar}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DirectParticipantTransactionWizard;
