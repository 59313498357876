import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";
import {
  callAPI,
  controllerAPI,
  getDisbursementData,
} from "../../API/FP/Actions";
import {
  contextObj,
  contextObjforapicall,
  dateFormat,
  requestStates,
} from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import { PlacedRequestDisbursementMockData } from "../../MockData/AnchorTraderMock";
import Pagenotfound from "../../Common/pagenotfound";
// import CreditRecommendationReport from "./CreditRecommendationReport";

function RequestSettlement({}) {
  const [creditScoreReportCheck, setCreditScoreReportCheck] =
    React.useState(false);
  const { state } = useLocation();
  let navigate = useNavigate();
  // let navigate = useNavigate();
  const location = useLocation();
  const requestData = location.state?.requestData;
  const token = location.state?.authToken;

  const [escrow, setEscrowDetails] = useState([]);
  const [fundsdetails, setfundsDetails] = useState([]);
  const [financeRequest, setfinanceRequest] = useState([]);
  const [settlementDetails, setsettlementDetails] = useState([]);
  const [participantSettlementList, setparticipantSettlementList] = useState(
    []
  );
  const [pftParticipantSettlement, setPftParticipantSettlement] = useState([]);
  const [repaymentList, setrepaymentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useAuth();
  const [atParticipantSettlement, setATParticipantSettlement] = useState([]);
  const [fpParticipantSettlement, setFPParticipantSettlement] = useState([]);
  const [acceptedOffers, setAcceptedOffers] = useState([]);
  const [disbursementList, setDisbursementList] = useState([]);
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const [
    fundsTransferTransactionDetailsList,
    setFundsTransferTransactionDetailsList,
  ] = useState([]);

  useEffect(() => {
    const fetchSettllementData = async () => {
      setIsLoading(true);
      try {
        const constructObj = contextObjforapicall(authContextObj, state.fid);
        const settlementresponse = await controllerAPI(
          "post",
          auth?.token,
          constructObj,
          "get-settlement-details"
        );
        if (settlementresponse?.response?.status === 401) {
          setAuth({
            ...auth,
            user: null,
            token: "",
            userDisplayName: "",
            userDetails: null,
          });
          localStorage.removeItem("auth");
          navigate("/creditbazaar/");
        }

        if (settlementresponse.res.status === 200) {
          const filteredData1 =
            settlementresponse?.res?.data?.data?.settlementList;
          const filteredData2 =
            settlementresponse?.res?.data?.data?.escrowTransactionDetailsList;
          const filteredData3 =
            settlementresponse?.res?.data?.data
              ?.fundsTransferTransactionDetailsList;
          const filteredData4 =
            settlementresponse?.res?.data?.data?.financeRequest;
          const filteredData5 =
            settlementresponse?.res?.data?.data?.participantSettlementList;
          const filteredData6 =
            settlementresponse?.res?.data?.data?.repaymentList;
          const filterData7 =
            settlementresponse?.res?.data?.data?.disbursementList;
          const filterData8 =
            settlementresponse?.res?.data?.data
              ?.fundsTransferTransactionDetailsList;
          setparticipantSettlementList(filteredData5);
          setrepaymentList(filteredData6);
          setsettlementDetails(filteredData1);
          setEscrowDetails(filteredData2);
          setfundsDetails(filteredData3);
          setfinanceRequest(filteredData4);
          setDisbursementList(filterData7);
          console.log("filterData8", filterData8);

          setFundsTransferTransactionDetailsList(filterData8);

          const pftParticipantSettlementt = filteredData5
            ?.filter(
              (item) =>
                item.settlementType === "PFT_SETTLEMENT" &&
                item.chargeType === "AT_TRANSACTION_CHARGES"
            )
            .map((item) => {
              return item;
            });
          setPftParticipantSettlement(pftParticipantSettlementt);

          const ATParticipantSettlemen = filteredData5
            ?.filter((item) => item.settlementType === "AT_SETTLEMENT")
            .map((item) => {
              return item;
            });
          setATParticipantSettlement(ATParticipantSettlemen);

          const FPParticipantSettlemen = filteredData5
            ?.filter((item) => item.settlementType === "FP_SETTLEMENT")
            .map((item) => {
              return item;
            });

          setFPParticipantSettlement(FPParticipantSettlemen);
          console.log("filteredData1 is :", filteredData1[0]?.acceptedoffer);
          setAcceptedOffers(filteredData1[0]?.acceptedoffer);
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettllementData();
  }, [requestData, auth?.token, state]);

  if (isLoading) {
    return (
      <div>
        {" "}
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      </div>
    );
  }
  // console.log("settlement data from api ", settlementDetails);
  // console.log("settlement escrow data from api ", escrow);
  // console.log("settlement funds data from api ", fundsdetails);

  const combinedArray = settlementDetails?.map((stsbitem, index) => ({
    ...stsbitem,
    escrowDetails: escrow[index],
    financeRequestt: financeRequest,
    fundsDetails: fundsdetails[index],
    participentlis: participantSettlementList[index],
    repayment: repaymentList[index],
    pftParticipantSettlement: pftParticipantSettlement[index],
    atParticipantSettlement: atParticipantSettlement[index],
    acceptedOffers: acceptedOffers,
    disburmentMap: disbursementList[index],
    fundsTransferTransactionDetailsList:
      fundsTransferTransactionDetailsList[index],
  }));

  console.log("atParticipantSettlement is ", combinedArray);

  // console.log("data from the combined api :", combinedArray);
  //console.log("data from the combined array :", combinedArray);

  return (
    <>
      <div>
        <RequestInformation className="body-text">
          <div className="main-content">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3
                style={{
                  marginBottom: "5px",
                  color: "#4A3AFF",
                  marginTop: "0px",
                }}
                className="section-heading"
              >
                {" "}
                Settlement Details
              </h3>
            </div>
            <div className="record-details">
              {combinedArray.length == 0 && !isLoading ? (
                <>
                  <Pagenotfound />
                </>
              ) : (
                <>
                  <table>
                    <tbody>
                      {combinedArray?.map((item, index) =>
                        index === 0 ? (
                          item.settlementStatus === "stcr" ? (
                            <React.Fragment key={index}>
                              <tr>
                                <th>Disbursement amount to you</th>
                                <td>
                                  {item?.disburmentMap?.amountToBeDisbursed}
                                </td>
                              </tr>
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={index}>
                              <tr>
                                <th>Disbursement amount to you</th>
                                <td>
                                  {item.disburmentMap?.amountToBeDisbursed}
                                </td>
                              </tr>
                              <tr>
                                <th>Amount repaid by you</th>
                                <td>{item.repayment?.repaymentAmtReceived}</td>
                              </tr>
                              <tr>
                                <th>Interest Withheld by FP</th>
                                <td>{item.acceptedOffers?.interestValue}</td>
                              </tr>
                              <tr>
                                <th>Pending Repayment amount</th>
                                <td>{item?.repayment?.amtToBeRepayed}</td>
                              </tr>
                              <tr>
                                <th>AT Settlement amount</th>
                                <td>
                                  {
                                    item?.atParticipantSettlement
                                      ?.settlementAmount
                                  }
                                </td>
                              </tr>
                              {/* <tr>
                    <th></th>
                    <td></td>
                  </tr> */}

                              <tr>
                                <th>Trade Advisory Fee </th>
                                <td>
                                  {
                                    item?.pftParticipantSettlement
                                      ?.settlementAmount
                                  }
                                </td>
                              </tr>

                              {/* Settlement part -1 done! */}

                              {/* <tr>
                        <th>Interest charges</th>
                        <td></td>
                      </tr> */}
                            </React.Fragment>
                          )
                        ) : null
                      )}
                    </tbody>
                  </table>

                  <table style={{ marginTop: "50px" }}>
                    <tbody>
                      {combinedArray?.map((item, index) =>
                        index === 0 ? (
                          <React.Fragment key={index}>
                            <tr>
                              <th>Settlement Amount paid to you</th>
                              <td>
                                {
                                  item?.atParticipantSettlement
                                    ?.settlementAmount
                                }
                              </td>
                            </tr>
                            <tr>
                              <th>Settlement Date</th>
                              <td>
                                {new Date(
                                  item?.atParticipantSettlement?.settlementDate
                                ).toLocaleDateString() || "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>Settlement Status</th>
                              <td>
                                {item?.atParticipantSettlement
                                  ?.participantSettlementStatus.length > 0 ? (
                                  <>
                                    {
                                      // requestStates(
                                      //   item?.atParticipantSettlement
                                      //     ?.participantSettlementStatus
                                      //)
                                      item?.atParticipantSettlement
                                        ?.atStatusDescCode
                                    }
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        ) : null
                      )}
                    </tbody>
                  </table>

                  <table style={{ marginTop: "50px" }}>
                    <tbody>
                      {combinedArray?.map((item, index) =>
                        index === 0 ? (
                          <React.Fragment key={index}>
                            <tr>
                              <th>Transactions Reference Number</th>
                              <td>
                                {
                                  item?.fundsTransferTransactionDetailsList
                                    ?.transactionRefNo
                                }
                              </td>
                            </tr>
                            <tr>
                              <th>Transaction Date</th>
                              <td>
                                {new Date(
                                  item?.fundsTransferTransactionDetailsList?.transactionDateTime
                                ).toLocaleDateString() || "-"}
                              </td>
                            </tr>
                            <tr>
                              <th>Credit Account No.</th>
                              <td>
                                {
                                  item?.fundsTransferTransactionDetailsList
                                    ?.creditAccountNumber
                                }
                              </td>
                            </tr>
                          </React.Fragment>
                        ) : null
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </RequestInformation>
      </div>

      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestSettlement;

const Finance1 = styled.div`
  .header {
    font-size: 18px;
    margin-top: 15px;
  }
  .custom-link:hover {
    color: #4a3aff;
  }
`;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 80% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    width: 55%;
  }
  table input {
    width: 100%;
    padding: 10px 0px;
  }
  input,
  textarea {
    border: 1px solid rgba(0, 0, 128, 0.2);
    background-color: rgba(229, 234, 252, 0.3);
  }
`;
