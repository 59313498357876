import React from "react";
import styled from "styled-components";

const Transactions = ({ PerformanceSummaryObj2 }) => {
  console.log("Transaction is :", PerformanceSummaryObj2);

  return (
    <TransactionContainer>
      {/* Weighted Metrics Section */}
      <div className="weighted-metrics">
        <div className="main-title">Weighted Metrics</div>
        <div className="metrics-grid">
          <div>
            <div className="metric-header">Weighted ROI</div>
            <div className="metric-value">{"-"}</div>
          </div>
          <div>
            <div className="metric-header">Weighted Tenure (days)</div>
            <div className="metric-value">{"-"}</div>
          </div>
        </div>
      </div>

      {/* Transactions Table */}
      <div className="main-title">Transactions</div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="sort-icon">Deal Date</th>
              <th className="sort-icon">Borrower</th>
              <th className="sort-icon">Period</th>
              <th className="sort-icon">Bill Value</th>
              <th className="sort-icon">Discount</th>
              <th className="sort-icon">Loan</th>
              <th className="sort-icon">Interest rate</th>
              <th className="sort-icon">Interest Amount</th>
              <th className="sort-icon">Net Payment</th>
              <th className="sort-icon">Payment detail</th>
              <th>Offer No</th>
            </tr>
          </thead>
          <tbody>
            {PerformanceSummaryObj2.map((row, index) => (
              <tr key={index}>
                <td>{row.disbursement_date}</td>
                <td>{row.anchor_trader_name}</td>
                <td>{row.term}</td>
                <td className="currency">{row.bill_value}</td>
                <td>{row.discount}</td>
                <td className="currency">{row.loan}</td>
                <td>{row.interest_rate}</td>
                <td className="currency">{row.interest_amount}</td>
                <td className="currency">{row.pby_net_amount}</td>
                <td>{row.payment_details || "-"}</td>
                <td>{row.accepted_offer_ref_no}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </TransactionContainer>
  );
};

export default Transactions;

const TransactionContainer = styled.div`
  .main-title {
    background: #a4c2f4;
    color: #000;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .weighted-metrics {
    margin-bottom: 20px;
  }

  .metrics-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .metric-header {
    background: #00b894;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: 500;
  }

  .metric-value {
    background: #f8f9fa;
    padding: 10px;
    text-align: center;
  }

  .table-container {
    overflow-x: auto;
    margin-top: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
  }

  th {
    background: #00b894;
    color: white;
    padding: 8px 12px;
    text-align: left;
    font-weight: 500;
    white-space: nowrap;
  }

  td {
    padding: 8px 12px;
    background: #f8f9fa;
    border-bottom: 1px solid #e5e7eb;
  }

  tr:nth-child(even) td {
    background: #f0f0f0;
  }

  .currency::before {
    content: "₹ ";
  }

  .sort-icon::after {
    content: "↕";
    margin-left: 4px;
  }
`;
