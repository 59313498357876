import React from "react";
import { createSlice } from "@reduxjs/toolkit";

const ActivateRquestOfferWizardSlice = createSlice({
  name: "Activate Request Offer Slice",

  initialState: {
    data: "",
    status: "Idle",
    selectedRo: "",
  },
  reducers: {
    getApiDataAcceptRo: (state, action) => {
      state.data = action.payload;
    },

    getSelectedRequestOffer: (state, action) => {
      state.selectedRo = action.payload;
    },
  },
});

export const { getApiDataAcceptRo, getSelectedRequestOffer } =
  ActivateRquestOfferWizardSlice.actions;

export default ActivateRquestOfferWizardSlice.reducer;
