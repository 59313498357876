import React, { useState, useEffect } from "react";
import "../PoolingAccount/PoolingAccountWizard.css";
import { useAuth } from "../../../context/auth";
import { ContextObjForPoolingAccnt } from "../../Common/Constants";
import {
  callAPIPoolingAccount,
  getAPIPoolingAccount,
} from "../../API/FP/Actions";
import { useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";

const PoolingAccountWizard = () => {
  const [auth, setAuth] = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showBtn, setShowBtn] = useState(true);
  const navigate = useNavigate();
  const poolingAccTransferTransactionDetails = {
    poolingAccountNumber: "",
    valDt: "",
    pstDt: "",
    pstTm: "",
    desc: "",
    amt: "",
    amtInd: "",
    bal: "",
    balInd: "",
    extndNarr: "",
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const [formData, setFormData] = useState(
    poolingAccTransferTransactionDetails
  );

  const [poolingAccounts, setPoolingAccounts] = useState([]);

  useEffect(() => {
    async function fetchPoolingAccountNumbers() {
      try {
        const response = await getAPIPoolingAccount(auth?.token);
        if (response?.res?.data?.data) {
          const accounts = response.res.data.data.map(
            (item) => item.poolingAccNumber
          );
          setPoolingAccounts(accounts);
        }
      } catch (error) {
        console.error("Error fetching pooling accounts:", error);
      }
    }

    fetchPoolingAccountNumbers();
  }, []);

  const transactionTypeOptions = ["DBIT", "CRDT"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const convertToUTC = (datetime) => {
      if (datetime) {
        const date = new Date(datetime);
        const isoString = date.toISOString();
        return isoString.slice(0, 19) + "Z";
      }
      return null;
    };
    const valDateTime = convertToUTC(formData.valDt);
    const pstDateTime1 = convertToUTC(formData.pstDt);
    const pstDateTime2 = convertToUTC(formData.pstTm);
    const updatedFormData = {
      ...formData,
      valDt: valDateTime,
      pstDt: pstDateTime1,
      pstTm: pstDateTime2,
    };
    const controllerObj = ContextObjForPoolingAccnt(updatedFormData);
    try {
      const apiResponse = await callAPIPoolingAccount(
        auth?.token,
        controllerObj,
        `create-pooling-account-transfer-transaction-details`
      );
      if (apiResponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }
      if (apiResponse?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Saved Details Successfully");
        setAlertSeverity("success");
        setShowBtn(false);
        setTimeout(window.location.reload(), 7000);
      }
      if (apiResponse?.response?.status === 400) {
        setOpenSnackbar(true);
        setApiResultMessage(
          "Oops! It looks like some fields are empty. Please complete all required information to proceed."
        );
        setAlertSeverity("error");
      }
    } catch (error) {
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured while saving");
      setAlertSeverity("error");
      console.error("Error during API call:", error);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setFormData(poolingAccTransferTransactionDetails);
  };

  return (
    <div className="parent">
      <div className="content">
        <p>Record Pooling Account Transaction</p>
        <p>Enter details for direct transactions to the pooling account.</p>
      </div>
      <form onSubmit={handleSubmit}>
        <p className="background">Pooling account information</p>
        <p className="labelText">
          Pooling account number:
          <br></br>
          <select
            className="styled-input"
            name="poolingAccountNumber"
            value={formData.poolingAccountNumber}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Pooling Account Number
            </option>
            {poolingAccounts.map((account) => (
              <option
                key={account.poolingAccountNumber}
                value={account.poolingAccountNumber}
              >
                {account}
              </option>
            ))}
          </select>
        </p>
        <p className="background">Transaction details</p>
        <div className="flex">
          <p className="labelText">
            Value Date
            <input
              className="styled-input"
              type="datetime-local"
              name="valDt"
              value={formData.valDt}
              onChange={handleChange}
              required
            />
          </p>
          <p className="labelText">
            Posting Date
            <input
              className="styled-input"
              type="datetime-local"
              name="pstDt"
              value={formData.pstDt}
              onChange={handleChange}
              required
            />
          </p>
          <p className="labelText">
            Posting Time
            <input
              className="styled-input"
              type="datetime-local"
              name="pstTm"
              value={formData.pstTm}
              onChange={handleChange}
              required
            />
          </p>
        </div>
        <p className="background">Financial details</p>
        <div className="flex">
          <p className="labelText">
            Amount
            <input
              placeholder="Enter Amount"
              className="styled-input"
              type="number"
              step="0.01"
              name="amt"
              value={formData.amt}
              onChange={handleChange}
              required
            />
          </p>
          <p className="labelText">
            Amount Indicator
            <select
              className="styled-input"
              name="amtInd"
              value={formData.amtInd}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Indicator
              </option>
              {transactionTypeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </p>
          <p className="labelText">
            Balance
            <input
              placeholder="Enter Balance"
              className="styled-input"
              type="number"
              step="0.01"
              name="bal"
              value={formData.bal}
              onChange={handleChange}
              required
            />
          </p>
          <p className="labelText">
            Balance Indicator
            <select
              className="styled-input"
              name="balInd"
              value={formData.balInd}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select Indicator
              </option>
              {transactionTypeOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </p>
        </div>
        <p className="labelText">
          Description
          <br></br>
          <input
            placeholder="Enter Description"
            className="styled-textbox"
            type="text"
            name="desc"
            value={formData.desc}
            onChange={handleChange}
            required
          />
        </p>
        <p className="background">Transaction Description</p>
        <div className="flex">
          <p className="labelText">
            Extended Narrative
            <br></br>
            <input
              type="text"
              placeholder="Enter Description"
              className="styled-input-large"
              name="extndNarr"
              value={formData.extndNarr}
              onChange={handleChange}
              required
            />
          </p>
        </div>
        <div className="btn-container">
          <button
            type="submit"
            className="submit-btn"
            disabled={!showBtn}
            style={{ opacity: showBtn ? "1" : "0.5" }}
          >
            Submit
          </button>
          <button className="reset-btn" onClick={handleReset}>
            Reset
          </button>
        </div>
      </form>
      <Snackbar
        open={openSnackbar}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PoolingAccountWizard;
