import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Step2 from "./Step2";
import Step1 from "./Step1";
import Step3 from "./Step3";
import { CircularProgress, Step, StepLabel, Stepper } from "@mui/material";

//get-finance-requests
const RequestOfferWizaard = () => {
  const steps = ["Step 1 ", "Step 2", "Step 3"];
  const [step, setStep] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
    setActiveStep((prevStep) => prevStep + 1);
  };

  window.history.pushState(null, "", window.location.href);
  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
    setActiveStep((prevStep) => prevStep - 1);
  };

  const goBack = () => {
    setStep((prevStep) => prevStep - 2);
    setActiveStep((prevStep) => prevStep - 2);
  };
  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 nextStep={nextStep} />;

      case 2:
        return <Step2 nextStep={nextStep} />;
      case 3:
        return <Step3 />;

      default:
        return null;
    }
  };

  return (
    <>
      <>
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            fontFamily: "Arial, sans-serif",
            padding: "20px",
            maxWidth: "600px",
            margin: "auto",
            border: "1px solid #ddd",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(107, 103, 103, 0.1)",
            backgroundColor: "white",
            marginTop: "50px",
          }}
        >
          <div>
            <Stepper
              activeStep={step - 1}
              style={{ margin: "0px 200px ", width: "500px" }}
            >
              {steps.map((label, index) => (
                <Step key={index}>
                  <div
                    style={{
                      opacity: activeStep === index ? "1" : "0.5",
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    <StepLabel className="StepLabel">{label}</StepLabel>
                  </div>
                </Step>
              ))}
            </Stepper>
          </div>
          <h1 style={{ color: "#333" }}>Create Request Offer</h1>

          {renderStep()}
        </div>
      </>
    </>
  );
};

export default RequestOfferWizaard;

const Acceptedoff = styled.div`
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
`;
