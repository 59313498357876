import React, { useState } from "react";
import "../Receivables/receivables.css";
import { useNavigate } from "react-router-dom";

const Receivables = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState(true);
  const data = [
    {
      financeRequestID: "FR12345",
      clientName: "Maria Anders",
      organisationName: "Germany",
      offerAcceptedDate: "2024-12-01",
      status: "Accepted",
    },
    {
      financeRequestID: "FR12346",
      clientName: "Francisco Chang",
      organisationName: "Mexico",
      offerAcceptedDate: "2024-12-05",
      status: "Pending",
    },
    {
      financeRequestID: "FR12347",
      clientName: "Roland Mendel",
      organisationName: "Austria",
      offerAcceptedDate: "2024-12-10",
      status: "Declined",
    },
  ];
  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = () => {
    const newFilteredData = data.filter((item) => {
      const matchesSearchText =
        item.financeRequestID
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.clientName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.organisationName.toLowerCase().includes(searchText.toLowerCase());

      const matchesDate = date ? item.offerAcceptedDate === date : true;
      const matchesStatus = status
        ? item.status.toLowerCase() === status.toLowerCase()
        : true;

      return matchesSearchText && matchesDate && matchesStatus;
    });

    setFilteredData(newFilteredData);
  };

  const handleFilter = () => {
    setFilter((prevFilter) => !prevFilter);
  };

  const handleSelectClick = (item) => {
    navigate("/creditbazaar/ManageFinanceRequest", { state: item });
  };

  return (
    <div className="parent">
      <div className="content">
        <p>Finance Requests</p>
        <p>View and manage finance requests with accepted offers.</p>
      </div>
      <div className="flex">
        <div className="input">
          <input
            type="text"
            placeholder="Search by Finance Request ID, Client Name..etc"
            className="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <button className="search" onClick={handleSearch}>
          Search
        </button>
        <button className="filter" onClick={handleFilter}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M10.7787 17.5V16H14.2115V17.5H10.7787ZM6.904 12.75V11.25H18.0865V12.75H6.904ZM4 8V6.5H21V8H4Z"
              fill="#5F6368"
            />
          </svg>
          Filter
        </button>
      </div>
      {filter && (
        <div
          className="flex"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <div className="flex">
            <div>
              <p className="smallText">Date</p>
              <input
                className="date"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div>
              <p className="smallText">Status</p>
              <select
                value={status}
                className="status"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="Accepted">Accepted Offers Only</option>
                <option value="Pending">Pending Offers</option>
                <option value="Declined">Declined Offers</option>
              </select>
            </div>
          </div>
          <button
            className="reset"
            onClick={() => {
              setSearchText("");
              setDate("");
              setStatus("");
            }}
          >
            Reset
          </button>
        </div>
      )}
      <table className="finance-table">
        <thead>
          <tr className="finance-header">
            <th>Finance Request ID</th>
            <th>Client Name</th>
            <th>Organisation Name</th>
            <th>Offer Accepted Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            filteredData.map((item) => (
              <tr key={item.financeRequestID} className="finance-row">
                <td>{item.financeRequestID}</td>
                <td>{item.clientName}</td>
                <td>{item.organisationName}</td>
                <td>{item.offerAcceptedDate}</td>
                <td>{item.status}</td>
                <td>
                  <button
                    className="select"
                    onClick={() => handleSelectClick(item)}
                  >
                    Select
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No matching records found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Receivables;
