import { useAuth } from "../../../context/auth";
import React, { useState } from "react";
import { CreateRequestOffer } from "../../Common/Constants";
import { controllerAPI } from "../../API/FP/Actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material";

const Step3 = ({ prevStep }) => {
  const [interestPercentage, setInterestPercentage] = useState(0);
  const [MarginPercentage, setMarginPercentage] = useState(0);
  const selectedFR = useSelector(
    (state) => state?.IndividualAssessmentReducer?.selectedFinanceRequest
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [btnDisable, setButtonDisable] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState(0);
  const [auth, setAuth] = useAuth();
  const [disableBtn, setDisableBtn] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const backClicked = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  const handleCreateRequestOffer = async (
    financeRequestId,
    interestPercentage,
    term
  ) => {
    if (
      !financeRequestId ||
      !interestPercentage ||
      !term ||
      interestPercentage <= 0 ||
      interestPercentage >= 100 ||
      MarginPercentage >= 100 ||
      term <= 0
    ) {
      setApiResultMessage(
        "All fields are required and must have valid values."
      );
      setAlertSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);
    try {
      const constructObj = CreateRequestOffer(
        financeRequestId,
        interestPercentage,
        MarginPercentage,
        term
      );
      const response = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "admin-process"
      );

      console.log("API Response:", response);

      if (response?.res?.status === 200) {
        const requestId =
          response?.res?.data?.data?.requestOffer?.id || "Unknown ID";
        setButtonDisable(true);
        setApiResultMessage(
          `For Finance Request ID: ${financeRequestId}, a Request Offer has been created successfully! Offer ID: ${requestId} redirecting to first screen ....... `
        );
        setAlertSeverity("success");
        setOpenSnackbar(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (response?.response?.status === 400) {
        setButtonDisable(true);
        console.log("Error is:", response?.response?.data?.message);
        setOpenSnackbar(true);
        setApiResultMessage(
          response?.response?.data?.message || "Unknown error"
        );
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error creating request offer:", error);
      setApiResultMessage(
        `Error creating request offer for Finance Request ID: ${financeRequestId}`
      );
      setAlertSeverity("error");
      setOpenSnackbar(true);
      setDisableBtn(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Snackbar Component */}
      <Snackbar
        open={openSnackbar}
        //autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        // Form
        <form
          style={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateRequestOffer(selectedFR, interestPercentage, term);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <label
              style={{ fontSize: "16px", color: "#555", fontWeight: "500" }}
            >
              Interest Percentage*:
            </label>
            <input
              required
              type="number"
              placeholder="Enter Interest Percentage"
              style={{
                width: "100%",
                height: "45px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                padding: "0 10px",
                fontSize: "16px",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
              onChange={(e) => setInterestPercentage(Number(e.target.value))}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <label
              style={{ fontSize: "16px", color: "#555", fontWeight: "500" }}
            >
              Term (Months)*:
            </label>
            <input
              required
              type="number"
              placeholder="Enter Term in Months"
              style={{
                width: "100%",
                height: "45px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                padding: "0 10px",
                fontSize: "16px",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
              onChange={(e) => setTerm(Number(e.target.value))}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <label
              style={{ fontSize: "16px", color: "#555", fontWeight: "500" }}
            >
              Margin Percentage *:
            </label>
            <input
              type="number"
              required
              placeholder="Enter Margin Percentage"
              style={{
                width: "100%",
                height: "45px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                padding: "0 10px",
                fontSize: "16px",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
              onChange={(e) => setMarginPercentage(Number(e.target.value))}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              marginTop: "20px",
              alignItems: "center",
            }}
          >
            <button
              type="submit"
              style={{
                fontSize: "15px",
                backgroundColor: "#297fff",
                fontFamily: "Open Sans",
                fontWeight: "400",
                color: "white",
                height: "50px",
                width: "180px",
                border: "0px",
                borderRadius: "3px",
                cursor: "pointer",
                opacity: btnDisable ? "0.5" : "1",
              }}
              disabled={btnDisable}
            >
              Create Request Offer
            </button>
            <button
              className="Backbtn"
              style={{
                color: "black",
                height: "50px",
                width: "180px",
                marginRight: "10px",
                backgroundColor: "white",
                border: "1px solid",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 36px",
                borderRadius: "3px",
                cursor: "pointer",
                alignSelf: "flex-start",
              }}
              onClick={backClicked}
            >
              Back
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Step3;
