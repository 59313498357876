import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import {
  controllerObjectForCAMReport,
  dateFormat,
} from "../../Common/Constants";
import { controllerAPI } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { Box } from "@mui/system";
import view from "../../images/view.png";
function TradeReport({ invoicesData, financeRequestData }) {
  const { state } = useLocation();
  let navigate = useNavigate();

  let tableHeaderNames = [
    "Invoice Date",
    "Invoice Number",
    "Anchor Trader",
    "Trade Partner ",
    "Trade Amount (INR)",
    "Term (Days)",
    "CAM Report ",
  ];

  // Pagination  variables
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const totalPages = Math.ceil(invoicesData.length / pageSize);
  const lastindex = currentPage * pageSize;
  const firstindex = lastindex - pageSize;
  const recordss = invoicesData.slice(firstindex, lastindex);
  const numbers = [...Array(totalPages + 1).keys()].slice(1);
  const [customtradeamnt, setcustomtradeamnt] = useState();
  const [loadig, setisLoading] = useState(false);
  const [auth, setAuth] = useAuth();
  const [openPopup, setOpenPopup] = useState(false);
  const [camReportUrl, setCamReportUrl] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const handleCamReport = async (url) => {
    setisLoading(true);
    console.log("The url is:", url);

    try {
      const controllerOject = controllerObjectForCAMReport(authContextObj, url);
      const apiresp = await controllerAPI(
        "post",
        auth?.token,
        controllerOject,
        "get-presigned-url-cam-reports"
      );
      if (apiresp?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        Navigate("/creditbazaar/");
      }
      if (apiresp?.res?.status === 200) {
        handleOpenPopup(apiresp?.res?.data?.data?.signed_url);
        setOpenSnackbar(true);
        setApiResultMessage("CAM Report generated successfully !");
        setAlertSeverity("success");
      }
      console.log("the apis response is :", apiresp);
      if (apiresp?.response?.status === 400) {
        setOpenSnackbar(true);
        setApiResultMessage(apiresp?.response?.data?.message);
        setAlertSeverity("error");
      }
    } catch (e) {
      console.log("Error is :", e);
      setOpenSnackbar(true);
      setApiResultMessage("Error in generating CAM Report!");
      setAlertSeverity("error");
      setisLoading(false);
    } finally {
      setisLoading(false);
    }
  };
  // Extracting trade data for table

  const handleOpenPopup = (url) => {
    setCamReportUrl(url);
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  let tableData = [
    {
      date: "Nov 18, 2023",
      invoiceNumber: "INV569829",
      anchorTrader: "Prime Textiles",
      tradePartnerName: "Apollo Hospitals",
      tradeAmount: "4,50,000",
      term: 80,
    },
    // {
    //   date: "Nov 19, 2023",
    //   invoiceNumber: "INV403441",
    //   tradePartnerName: "Renova Hospitals",
    //   tradeAmount: "3,00,000",
    //   term: 70,
    // },
    // {
    //   date: "Nov 19, 2023",
    //   invoiceNumber: "INV457608",
    //   tradePartnerName: "Renova Hospitals",
    //   tradeAmount: "3,50,000",
    //   term: 70,
    // },
  ];
  const customtradeAmount = (e) => {
    const Customtradeindividual = e.target.value;
    setcustomtradeamnt(Customtradeindividual);
  };
  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const ChangePage = (pageNumber) => () => setCurrentPage(pageNumber);
  console.log("999999999", recordss);

  return (
    <>
      <div>
        <RequestInformation>
          <div className="main-content">
            <div className="record-details">
              {loadig ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100vh",
                    }}
                  >
                    <CircularProgress />
                  </div>
                </>
              ) : (
                <>
                  <table>
                    <tbody>
                      <tr>
                        {tableHeaderNames?.map((x) => {
                          return <th>{x}</th>;
                        })}
                      </tr>

                      {recordss?.map((item) => {
                        return (
                          <tr>
                            <td>
                              {dateFormat(
                                new Date(item?.trademaster?.tradeDate),
                                0
                              )}
                            </td>
                            <td>{item?.trademaster?.tradeNo}</td>
                            <td>
                              {item?.trademaster?.sourceName?.length > 20 ? (
                                <Tooltip
                                  title={`${item?.trademaster?.sourceName}`}
                                  placement="top"
                                >
                                  <div
                                    className={
                                      `${item?.trademaster?.sourceName}`
                                        ?.length > 20 && `text-ellipse`
                                    }
                                    style={{ width: "120px" }}
                                  >
                                    {item?.trademaster?.sourceName}
                                  </div>
                                </Tooltip>
                              ) : (
                                <div
                                  className={
                                    `${item?.trademaster?.sourceName}`?.length >
                                      20 && `text-ellipse`
                                  }
                                  style={{ width: "120px" }}
                                >
                                  {item?.trademaster?.sourceName}
                                </div>
                              )}
                            </td>
                            <td>
                              {item?.trademaster?.targetName?.length > 20 ? (
                                <Tooltip
                                  title={`${item?.trademaster?.targetName}`}
                                  placement="top"
                                >
                                  <div
                                    className={
                                      `${item?.trademaster?.targetName}`
                                        ?.length > 20 && `text-ellipse`
                                    }
                                    style={{ width: "120px" }}
                                  >
                                    {item?.trademaster?.targetName}
                                  </div>
                                </Tooltip>
                              ) : (
                                <div
                                  className={
                                    `${item?.trademaster?.targetName}`?.length >
                                      20 && `text-ellipse`
                                  }
                                  style={{ width: "120px" }}
                                >
                                  {item?.trademaster?.targetName}
                                </div>
                              )}
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={item?.trademaster?.tradeAmount}
                                thousandsGroupStyle="lakh"
                                thousandSeparator=","
                              />
                            </td>
                            <td>{item?.trademaster?.tradeTerms}</td>
                            <td>
                              {console.log("what i am getting:", item?.camLink)}
                              <Tooltip
                                title={
                                  item?.camLink
                                    ? "View Cam Report"
                                    : "No Cam Report Available"
                                }
                                arrow
                              >
                                <button
                                  style={{
                                    color: "black",
                                    height: "45px",
                                    width: "120px",
                                    backgroundColor: "white",
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "10px 20px",
                                    borderRadius: "3px",
                                    cursor: item?.camLink
                                      ? "pointer"
                                      : "not-allowed",
                                    opacity: item?.camLink ? "1" : "0.5",
                                  }}
                                  disabled={!item?.camLink}
                                  onClick={() => handleCamReport(item?.camLink)}
                                >
                                  <img
                                    src={view}
                                    alt="View Icon"
                                    style={{
                                      height: "24px",
                                      width: "24px",
                                      opacity: item?.camLink ? "1" : "0.5",
                                    }}
                                  />
                                </button>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {invoicesData.length !== 0 && (
                    <>
                      {" "}
                      <nav
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          width: "100%",
                        }}
                      >
                        <ul
                          style={{
                            display: "flex",
                            listStyleType: "none",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <li style={{ marginRight: "8px" }}>
                            <p
                              style={{
                                padding: "8px 12px",
                                borderRadius: "4px",
                                cursor:
                                  currentPage === 1 ? "not-allowed" : "pointer",
                                backgroundColor:
                                  currentPage === 1 ? "#f0f0f0" : "white",
                              }}
                              onClick={handlePrevPage}
                            >
                              {"<"}
                            </p>
                          </li>
                          {/* Showing current page and total pages information */}
                          <li
                            style={{
                              marginRight: "8px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "14px",
                            }}
                          >
                            Showing {currentPage} of {totalPages}
                          </li>
                          <li style={{ marginRight: "8px" }}>
                            <p
                              style={{
                                padding: "8px 12px",
                                borderRadius: "4px",
                                cursor:
                                  currentPage === totalPages
                                    ? "not-allowed"
                                    : "pointer",
                                backgroundColor:
                                  currentPage === totalPages
                                    ? "#f0f0f0"
                                    : "white",
                              }}
                              onClick={handleNextPage}
                            >
                              {">"}
                            </p>
                          </li>
                        </ul>
                      </nav>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <Dialog
            open={openPopup}
            onClose={handleClosePopup}
            maxWidth="md" // Sets the maximum width (e.g., 'xs', 'sm', 'md', 'lg', 'xl')
            fullWidth // Makes it take the full width up to maxWidth
          >
            <DialogTitle>Cam Report</DialogTitle>
            <DialogContent
              sx={{
                padding: 0, // Removes default padding to make the iframe fill the space
              }}
            >
              {/* Iframe for Cam Report */}
              <Box
                component="iframe"
                src={camReportUrl} // Dynamic URL
                title="Embedded Form"
                sx={{
                  width: "100%",
                  height: "60vh", // Height of iframe
                  border: "none",
                  borderRadius: "4px",
                }}
              ></Box>
            </DialogContent>

            {/* Close Button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "16px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleClosePopup}
                sx={{
                  textTransform: "none", // Avoid uppercase text
                }}
              >
                Close
              </Button>
            </Box>
          </Dialog>

          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default TradeReport;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 90%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    // box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
    background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;
