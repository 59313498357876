// src/components/widgets/MixedChartWidget.jsx
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MixedChartWidget = ({ data }) => {
  const [isInitialState, setIsInitialState] = useState(true);
  const [selectedMetrics, setSelectedMetrics] = useState(new Set(['totalSales', 'totalPurchases', 'salesCount', 'purchasesCount']));
  const [rightYValue,setRightYValue] = useState(200);
  const metrics = [
    { key: 'totalSales', name: 'Total Sales', color: '#4FD1C5', type: 'bar' },
    { key: 'totalPurchases', name: 'Total Purchases', color: '#35505B', type: 'bar' },
    { key: 'salesCount', name: 'Total Sales Count', color: '#FF4C61', type: 'line' },
    { key: 'purchasesCount', name: 'Total Purchases Count', color: '#FF8B00', type: 'line' }
  ];

  const transformedData = data?.map(item => ({
    totalSales: parseFloat(item.Total_Sales_SalesandPurchases),
    totalPurchases: parseFloat(item.Total_Purchases_SalesandPurchases),
    salesCount: item?.Total_Sales_Count,
    purchasesCount: item?.Total_Purchases_Count, 
    month: new Date(item.year_month).toLocaleString('default', { month: 'short', year: 'numeric' })
  }));

useEffect(()=>{
  const a = transformedData?.map((i) => i?.salesCount ?? 0) || [];
  const b = transformedData?.map((i) => i?.purchasesCount ?? 0) || [];
  const maxTotalSalesCount = a.length > 0 ? Math.max(...a) : 0;
  const maxTotalPurchaseCount = b.length > 0 ? Math.max(...b) : 0;
  
  console.log("maxTotalSalesCount  & maxTotalPurchaseCount is ", maxTotalSalesCount, maxTotalPurchaseCount);
  
  if (maxTotalSalesCount > maxTotalPurchaseCount) {
    setRightYValue(maxTotalSalesCount + 10);
  } else {
    setRightYValue(maxTotalPurchaseCount + 10);
  }
},[transformedData])

    
  
  const handleMetricClick = (metricKey) => {
    if (isInitialState) {
      setSelectedMetrics(new Set([metricKey]));
      setIsInitialState(false);
    } else {
      setSelectedMetrics(prev => {
        const newSelection = new Set(prev);
        if (newSelection.size === 1 && newSelection.has(metricKey)) {
          return newSelection;
        }
        if (newSelection.has(metricKey)) {
          newSelection.delete(metricKey);
        } else {
          newSelection.add(metricKey);
        }
        return newSelection;
      });
    }
  };

  return (
    <ChartContainer>
      <ChartTitle>Sales and Purchases</ChartTitle>
      <LegendContainer>
        {metrics.map((metric) => (
          <LegendButton 
            key={metric.key}
            active={selectedMetrics.has(metric.key)}
            color={metric.color}
            onClick={() => handleMetricClick(metric.key)}
          >
            <LegendIcon type={metric.type} color={metric.color} />
            {metric.name}
          </LegendButton>
        ))}
      </LegendContainer>
      <ResponsiveContainer width="100%" height={450}>
      <ComposedChart 
  data={transformedData}
  margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
>
  <CartesianGrid strokeDasharray="3 3" vertical={false} />
  <XAxis 
    dataKey="month" 
    tick={{ fill: '#666' }}
    axisLine={{ stroke: '#E5E7EB' }}
  />
  
  <YAxis 
    yAxisId="left"
    tick={{ fill: '#666' }}
    axisLine={{ stroke: '#E5E7EB' }}
    domain={[0, rightYValue/100]} 
  />

  <YAxis 
    yAxisId="right"
    orientation="right"
    tick={{ fill: '#666' }}
    axisLine={{ stroke: '#E5E7EB' }}
    domain={[0, rightYValue]}
    scale="linear" 
    tickFormatter={(value) => `${value}`} 
  />
  
  <Tooltip 
    contentStyle={{
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '8px'
    }}
  />
  
  {selectedMetrics.has('totalSales') && (
    <Bar 
      yAxisId="left"
      dataKey="totalSales" 
      fill="#4FD1C5"
      barSize={20}
      name="Total Sales"
    />
  )}
  
  {selectedMetrics.has('totalPurchases') && (
    <Bar 
      yAxisId="left"
      dataKey="totalPurchases" 
      fill="#35505B"
      barSize={20}
      name="Total Purchases"
    />
  )}
  
  {selectedMetrics.has('salesCount') && (
    <Line
      yAxisId="right"
      type="monotone"
      dataKey="salesCount"
      stroke="#FF4C61"
      strokeWidth={2}
      dot={{ r: 4, strokeWidth: 2 }}
      name="Total Sales Count"
    />
  )}
  
  {selectedMetrics.has('purchasesCount') && (
    <Line
      yAxisId="right"
      type="monotone"
      dataKey="purchasesCount"
      stroke="#FF8B00"
      strokeWidth={2}
      dot={{ r: 4, strokeWidth: 2 }}
      name="Total Purchases Count"
    />
  )}
</ComposedChart>

      </ResponsiveContainer>
    </ChartContainer>
  );
};

// Styled Components
const ChartContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 1rem;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12);
`;

const ChartTitle = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 1rem;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const LegendButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid ${props => props.color};
  border-radius: 4px;
  background: ${props => props.active ? props.color : 'white'};
  color: ${props => props.active ? 'white' : props.color};
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.color};
    color: white;
    opacity: 0.9;
  }
`;

const LegendIcon = styled.span`
  display: inline-block;
  width: 12px;
  height: ${props => props.type === 'bar' ? '12px' : '2px'};
  background-color: currentColor;
  margin-right: 8px;
  border-radius: ${props => props.type === 'bar' ? '2px' : '0'};
`;

export default MixedChartWidget;