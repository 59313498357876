import styled from "styled-components";
import {
  contextObjforNextWizardReconcile,
  contextObjReconcileBeneValAction,
  contextObjReconcileFundTransferAction,
  objectforBeneval,
  objectforOPSPrepareDisb,
  objectforRealfundsTransfer,
} from "../../Common/Constants";
import React, { useState } from "react";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { controllerAPI, opscontroller } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Step4 = ({
  step3output,
  step2output,
  nextStep,
  handleChange,
  formData,
  setstep4output,
  wizardRecordId,
  wizardRecordInstanceId,
  setWizardRecordInstanceId,
  wizardPageId,
  setWizardPageId,
  actionButtonId,
  setActionButtonId,
  NextButtonId,
  setNextButtonId,
}) => {
  const [btnenable, setbtnenable] = useState(false);
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const disbId = useSelector(
    (state) => state?.DisbursmentWizardSlice?.disbursmentID
  );
  console.log("chcecking now :", disbId);
  const navigate = useNavigate();
  const [wizardRecordInstanceIdStep1, setwizardRecordInstanceIdStep1] =
    useState(wizardRecordInstanceId);
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  const ActionButtonResponse = async (path) => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjReconcileFundTransferAction(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceId,
        wizardPageId,
        actionButtonId,
        path,
        disbId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `reconcile-disbursement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }
      if (ATTradesresponse?.response?.status === 500) {
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.message);
        setAlertSeverity("error");
      }
      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setActionButtonId(actionButtonId + 1);
        setwizardRecordInstanceIdStep1(
          ATTradesresponse?.res?.data?.data?.reconcileDisbursementWizardStepData
            ?.id
        );
        setOpenSnackbar(true);
        setApiResultMessage("Funds Transfered Successfully !");
        setAlertSeverity("success");
        try {
          const parseMsg = JSON.parse(
            ATTradesresponse?.res?.data?.data
              ?.reconcileDisbursementWizardStepData?.apiResponse
          );
          console.log("api response data is:", parseMsg);
          setstep4output(parseMsg?.data);
        } catch (error) {
          console.error("Failed to parse API response:", error);
        }

        setbtnenable(true);
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      if (error?.response?.status === 500) {
        setOpenSnackbar(true);
        setApiResultMessage(
          error?.response?.data?.message || "Error in funds transfer "
        );
        setAlertSeverity("error");
      }
    } finally {
      setLoading(false);
    }
  };

  const NextButton = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const constructObj = contextObjforNextWizardReconcile(
        authContextObj,
        wizardRecordId,
        wizardRecordInstanceIdStep1,
        wizardPageId,
        NextButtonId
      );
      // Updated API call with pagination parameters
      const ATTradesresponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        `reconcile-disbursement-wizard`
      );

      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (ATTradesresponse.res.status === 200) {
        console.log("sucess");
        setNextButtonId(NextButtonId + 1);
        setWizardRecordInstanceId(
          ATTradesresponse?.res?.data?.data?.reconcileDisbursementWizardStepData
            ?.id
        );
        setWizardPageId(wizardPageId + 1);
        nextStep();
      }
      if (ATTradesresponse?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          ATTradesresponse?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(ATTradesresponse?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in Reconcile Disbursement!");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const triggerfundstransfer = async (path) => {
    const constructObj = objectforRealfundsTransfer(path);
    try {
      setLoading(true);
      const response = await opscontroller(
        "post",
        auth?.token,
        constructObj,
        "trigger-funds-transfer"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response?.res?.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Funds Transfered Successfully !");
        setAlertSeverity("success");
        setstep4output(response?.res?.data?.data);
        setbtnenable(true);
      }
      if (response?.response?.status === 400) {
        console.log(
          "response?.response?.data?.error",
          response?.response?.data?.erro
        );
        setOpenSnackbar(true);
        setApiResultMessage(response?.response?.data?.error);
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error while transfering funds :", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error while transfering funds !");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <StepContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="step-content">
          <h2> Step 5:Trigger Funds Transfer </h2>
          <p>
            Please Click the Below Link to view Creditor and Debitor Bank
            Account Details:{" "}
            <a
              href={step2output?.fundTransferS3Path}
              target="_blank"
              style={{ fontWeight: "bold" }}
            >
              {step2output?.fundTransferS3Path}
            </a>
            <iframe
              style={{ marginTop: "20px" }}
              src={step2output?.fundTransferS3Path}
              width="700px"
              height="200px"
            />
          </p>
          <p>
            Amount To be Transferred is Rs :{" "}
            <span style={{ fontWeight: "bold" }}>
              {step2output?.disbursement?.amountToBeDisbursed}/-
            </span>
          </p>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "400px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                disabled={btnenable}
                style={{
                  borderRadius: "5px",
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  height: "fit-content",
                  boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                  margin: "5px",
                  opacity: !btnenable ? "1" : "0.5",
                }}
                onClick={() => {
                  ActionButtonResponse(step2output?.fundTransferS3Path);
                }}
              >
                Proceed To Funds Transfer
              </Button>
            </div>
          )}

          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
        <button
          style={{
            borderRadius: "10px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            margin: "5px",
            opacity: btnenable ? "1" : "0.5",
            padding: "15px 30px",
            fontSize: "15px",
          }}
          onClick={NextButton}
          className="button"
          disabled={!btnenable}
        >
          Next
        </button>
      </div>
    </StepContainer>
  );
};

export default Step4;

const StepContainer = styled.div`
  .step-content {
    max-width: 600px;
    margin: auto;
  }

  .button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #0056b3;
  }
`;
