import React, { useState } from "react";
import {
  Box,
  TextField,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";

import { useAuth } from "../../../context/auth";
import { useSelector } from "react-redux";
import { constructObjForActivateRequestOffer } from "../../Common/Constants";
import { Navigate } from "react-router-dom";
import { controllerAPI, controllerAPIForROCrtn } from "../../API/FP/Actions";

const OfferDetailsForm = ({ setShowTabs }) => {
  const [loading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [auth, setAuth] = useAuth();

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: "1",
  };

  // Redux selectors to fetch data
  const data = useSelector(
    (state) => state?.activateRquestOfferWizardReducer?.data || []
  );
  const selectedRequestOffer = useSelector(
    (state) => state?.activateRquestOfferWizardReducer?.selectedRo
  );

  // Filter and extract request offer details
  const RoAndFRDetails = data.filter(
    (i) => i.requestOffer?.id === selectedRequestOffer
  );
  const roDetails = RoAndFRDetails.map((i) => i?.requestOffer) || [];

  // Initialize form state
  const initialFormData = roDetails.map((i) => ({
    actionByDate: i?.actionByDate || "",
    anchorTraderGSTComplianceScore: i?.anchorTraderGSTComplianceScore || "",
    anchorTraderName: i?.anchorTraderName || "",
    tradePartnerName: i?.tradePartnerName || "",
    tradeValue: i?.tradeValue || "",
    term: i?.term || "",
    marginPtg: i?.marginPtg || "",
    marginValue: i?.marginValue || "",
    interestPtg: i?.interestPtg || "",
    interestValue: i?.interestValue || "",
    amountAftMargin: i?.amountAftMargin || "",
    disbursalAmount: i?.disbursalAmount || "",
    financeMargin: i?.financeMargin || "",
    annualInterestRate: i?.annualInterestRate || "",
    annualInterestValue: i?.annualInterestValue || "",
    amountAfterMargin: i?.amountAfterMargin || "",
    disbursedAmount: i?.disbursedAmount || "",
  }));

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState([]);

  // Handle field changes
  const handleChange = (index, field, value) => {
    setFormData((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    );
    setErrors((prev) =>
      prev.filter((error) => error.index !== index || error.field !== field)
    );
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };
  // Validate the form

  const saveActivateRequestOffers = async () => {
    setIsLoading(true);
    try {
      const controllerObj = constructObjForActivateRequestOffer(
        authContextObj,
        selectedRequestOffer
      );

      const ATTradesresponse = await controllerAPIForROCrtn(
        "post",
        auth?.token,
        controllerObj,
        "activate-request-offer"
      );
      console.log("Success", ATTradesresponse);
      if (ATTradesresponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        Navigate("/creditbazaar/");
      }

      // if (ATTradesresponse.res.status === 200) {
      //   console.log("Success", ATTradesresponse);
      //   //nextStep();
      //   setIsLoading(false);
      // }
      if (ATTradesresponse.res.status === 200) {
        setIsLoading(false);
        setOpenSnackbar(true);
        setApiResultMessage(
          `Successfully activated the Request Offer ${selectedRequestOffer}!`
        );
        setAlertSeverity("success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      if (ATTradesresponse?.res?.status === 400) {
        setOpenSnackbar(true);
        setApiResultMessage("Error in activated the Request Offer  !");
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error fetching  details:", error);
      setOpenSnackbar(true);
      setApiResultMessage("Error in fetching details!");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };
  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    saveActivateRequestOffers();
  };

  const getError = (index, field) => {
    const error = errors.find((e) => e.index === index && e.field === field);
    return error ? error.message : "";
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{ margin: "0px 70px" }}
      onSubmit={handleSubmit}
    >
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        </>
      ) : (
        <>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              backgroundColor: "#ECF1FC",
              padding: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            Request Offer Details {selectedRequestOffer}
            <p style={{ fontSize: "15px" }}>
              The Below Details are Read-only &#x1F441;
            </p>
          </Typography>

          <Grid container spacing={3}>
            {formData.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Action By Date"
                    type="text"
                    name="actionByDate"
                    value={item.actionByDate}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      handleChange(index, "actionByDate", e.target.value)
                    }
                    error={!!getError(index, "actionByDate")}
                    helperText={getError(index, "actionByDate")}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Credit Score"
                    type="number"
                    name="anchorTraderGSTComplianceScore"
                    value={item.anchorTraderGSTComplianceScore}
                    placeholder="Enter Credit Score"
                    onChange={(e) =>
                      handleChange(
                        index,
                        "anchorTraderGSTComplianceScore",
                        e.target.value
                      )
                    }
                    error={!!getError(index, "anchorTraderGSTComplianceScore")}
                    helperText={getError(
                      index,
                      "anchorTraderGSTComplianceScore"
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Anchor Trader Name"
                    name="anchorTraderName"
                    value={item.anchorTraderName}
                    placeholder="Enter Anchor Trader Name"
                    onChange={(e) =>
                      handleChange(index, "anchorTraderName", e.target.value)
                    }
                    error={!!getError(index, "anchorTraderName")}
                    helperText={getError(index, "anchorTraderName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Trade Partner Name"
                    name="tradePartnerName"
                    value={item.tradePartnerName}
                    placeholder="Enter Trade Partner Name"
                    onChange={(e) =>
                      handleChange(index, "tradePartnerName", e.target.value)
                    }
                    error={!!getError(index, "tradePartnerName")}
                    helperText={getError(index, "tradePartnerName")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Total Trade Value (INR)"
                    type="number"
                    name="tradeValue"
                    value={item.tradeValue}
                    placeholder="Enter Total Trade Value"
                    onChange={(e) =>
                      handleChange(index, "tradeValue", e.target.value)
                    }
                    error={!!getError(index, "tradeValue")}
                    helperText={getError(index, "tradeValue")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Request Tenure (Days)"
                    type="number"
                    name="term"
                    value={item.term}
                    placeholder="Enter Request Tenure"
                    onChange={(e) =>
                      handleChange(index, "term", e.target.value)
                    }
                    error={!!getError(index, "term")}
                    helperText={getError(index, "term")}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>

          {/* Section: Final Offer Details */}

          <Typography
            variant="h6"
            sx={{
              mb: 3,
              mt: 3,
              backgroundColor: "#ECF1FC",
              padding: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            Final Offer Details
            <p style={{ fontSize: "15px" }}>
              The Below Details are Read-only &#x1F441;
            </p>
          </Typography>
          <Grid container spacing={3}>
            {formData.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Finance Margin (%)"
                    type="number"
                    name="marginPtg"
                    value={item.marginPtg}
                    placeholder="Enter Finance Margin"
                    onChange={(e) =>
                      handleChange(index, "marginPtg", e.target.value)
                    }
                    error={!!getError(index, "marginPtg")}
                    helperText={getError(index, "marginPtg")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Margin Value (INR)"
                    type="number"
                    name="marginValue"
                    value={item.marginValue}
                    placeholder="Enter Margin Value"
                    onChange={(e) =>
                      handleChange(index, "marginValue", e.target.value)
                    }
                    error={!!getError(index, "marginValue")}
                    helperText={getError(index, "marginValue")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Annualized Interest Rate (%)"
                    type="number"
                    name="interestPtg"
                    value={item.interestPtg}
                    placeholder="Enter Annualized Interest Rate"
                    onChange={(e) =>
                      handleChange(index, "interestPtg", e.target.value)
                    }
                    error={!!getError(index, "interestPtg")}
                    helperText={getError(index, "interestPtg")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Annualized Interest Value (INR)"
                    type="number"
                    name="interestValue"
                    value={item.interestValue}
                    placeholder="Enter Annualized Interest Value"
                    onChange={(e) =>
                      handleChange(index, "interestValue", e.target.value)
                    }
                    error={!!getError(index, "interestValue")}
                    helperText={getError(index, "interestValue")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Amount After Margin (INR)"
                    type="number"
                    name="amountAftMargin"
                    value={item.amountAftMargin}
                    placeholder="Enter Amount After Margin"
                    onChange={(e) =>
                      handleChange(index, "amountAftMargin", e.target.value)
                    }
                    error={!!getError(index, "amountAftMargin")}
                    helperText={getError(index, "amountAftMargin")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Amount to be Disbursed (INR)"
                    type="number"
                    name="disbursalAmount"
                    value={item.disbursalAmount}
                    placeholder="Enter Amount to be Disbursed"
                    onChange={(e) =>
                      handleChange(index, "disbursalAmount", e.target.value)
                    }
                    error={!!getError(index, "disbursalAmount")}
                    helperText={getError(index, "disbursalAmount")}
                  />
                </Grid>
                {/* New Fields Added */}
              </React.Fragment>
            ))}
          </Grid>

          {/* Submit Button */}
          <Box
            sx={{ textAlign: "center", mt: 4, display: "flex", gap: "15px" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: "180px", height: "45px", fontSize: "15px" }}
            >
              Activate
            </Button>
            <button
              className="Backbtn"
              style={{
                color: "black",
                height: "45px",
                width: "120px",
                backgroundColor: "white",
                border: "1px solid",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 36px",
                borderRadius: "3px",
                cursor: "pointer ",
                alignSelf: "flexStart",
              }}
              onClick={() => setShowTabs(false)}
            >
              Back
            </button>
            <Snackbar
              open={openSnackbar}
              //autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={alertSeverity}
                sx={{ width: "100%" }}
              >
                {apiResultMessage}
              </Alert>
            </Snackbar>
          </Box>
        </>
      )}
      {/* Section: Request Offer Details */}
    </Box>
  );
};

export default OfferDetailsForm;
