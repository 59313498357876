import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  callAPI,
  controllerAPI,
  controllerAPIForROCrtn,
} from "../../API/FP/Actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {
  contextObj,
  contextObjController,
  controllerObjectForReviewAcceptedOffer,
  dateFormat,
  kycContextObjController,
  onAcceptedOfferContextObjController,
  presignedContextObjController,
  requestStates,
} from "../../Common/Constants";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Input,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import { PlacedRequestInvoicesData } from "../../MockData/AnchorTraderMock";
import Modal from "../../Common/Modal";
import UpdatesIframe from "../../Common/UpdatesIframe";
import { Stack } from "@mui/system";
import { Timer } from "@mui/icons-material";

const RequestOfferReviewFullDetails = ({
  setShowFullOfferDetails,
  data,
  financeRequestData,
}) => {
  const location = useLocation();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const requestData = location.state?.requestData || {};
  const token = location.state?.authToken;
  const [presignedURl, setPresignedURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [auth, setAuth] = useAuth();
  const [checkBoxValue, setCheckboxValue] = useState(false);
  const [loanDate, setLoanDate] = useState("");
  const [legalCharges, setLegalCharges] = useState(0);
  const acceptedOfferId = data?.id;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [timer, setTimer] = useState(3);

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const CloseModal = () => {
    setModalOpen(false);
  };

  const handleDateChange = (e) => {
    const date = new Date(e.target.value);
    if (!isNaN(date.getTime())) {
      const isoString = date.toISOString().slice(0, 16);
      setLoanDate(isoString); // Update state with the formatted date
    } else {
      console.error("Invalid date value");
      setOpenSnackbar(true);
      setApiResultMessage("Invalid date value");
      setAlertSeverity("error");
    }
  };

  const simulateApiResponse = (success) => {
    setIsSuccess(success);
    setApiMessage(
      success
        ? "Reviewed the accepted offer and loan agreement, deductions details created.."
        : "Failed to review the accepted offer. Please try again."
    );
    setPopupOpen(true);

    //setPopupOpen(false);
  };

  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );
  const [countdown, setCountdown] = useState(3);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   //saveProfileDetails();
  // };

  const handleReviewOffer = async (date, legalCharges) => {
    setIsLoading(true);
    const controllerObject = controllerObjectForReviewAcceptedOffer(
      acceptedOfferId,
      date,
      legalCharges
    );
    try {
      setIsLoading(true);
      const apiResponse = await controllerAPIForROCrtn(
        "post",
        auth?.token,
        controllerObject,
        "review-accepted-offer"
      );
      console.log("apiResponse is", apiResponse?.response?.data?.message);
      if (apiResponse?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        Navigate("/creditbazaar/");
      }
      if (apiResponse?.response?.status === 400) {
        setOpenSnackbar(true);
        setApiResultMessage(apiResponse?.response?.data?.message);
        setAlertSeverity("error");
        simulateApiResponse(false);
      }
      if (apiResponse?.res?.status === 200) {
        console.log("Status is 200");
        setApiResultMessage("Accepted Offer reviewed successfully!!");
        setOpenSnackbar(true);
        setAlertSeverity("success");
        simulateApiResponse(true);
      }
    } catch (e) {
      console.log("error is :", e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  let hours = today.getHours();
  let minutes = today.getMinutes();
  let seconds = today.getSeconds();
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Loan Agreement Signed Date:", loanDate);
    console.log("Legal Charges:", legalCharges);
    handleReviewOffer(loanDate, legalCharges);
    // You can further process or send the data to an API here
  };

  const formattedToday =
    yyyy +
    "-" +
    mm +
    "-" +
    dd +
    "T" +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    "Z";
  console.log("from the expanded :", data);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setPopupOpen(false);
    setOpenSnackbar(false);
    window.location.reload();
  };
  return (
    <>
      {" "}
      {}
      <h2>Complete details of the Accepted offer </h2>
      <>
        <div>
          {isLoading ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            </>
          ) : (
            <>
              <form className="form" onSubmit={(e) => handleSubmit(e)}>
                <RequestInformation className="body-text">
                  <div className="main-content">
                    <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                      {" "}
                      Request details
                    </h3>
                    <div className="record-details">
                      <table style={{ width: "auto" }}>
                        <tbody>
                          <tr>
                            <th>Offer Accepted Date</th>
                            <td>{data?.offerAcceptedDate}</td>
                          </tr>
                          <tr>
                            <th>Total trade value (INR)</th>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={data?.value}
                                thousandsGroupStyle="lakh"
                                thousandSeparator=","
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </RequestInformation>
                <div style={{ display: "flex" }}>
                  <SideTables className="body-text" style={{ width: "50%" }}>
                    <div className="main-content">
                      <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                        {" "}
                        Disbursement details
                      </h3>
                      <div className="record-details">
                        <table style={{ width: "auto" }}>
                          <tbody>
                            <tr>
                              <th> Financing Margin(%)</th>
                              <td>{data?.marginPtg}</td>
                            </tr>
                            <tr>
                              <th>Margin value (INR)</th>
                              <td>{data?.marginValue}</td>
                            </tr>
                            <tr>
                              <th>Amount after margin (INR)</th>
                              <td>{data?.amountAftMargin}</td>
                            </tr>
                            <tr>
                              <th>Annualized Interest rate (%)</th>
                              <td>{data?.interestPtg}%</td>
                            </tr>
                            <tr>
                              <th>Interest value (INR)</th>
                              <td>{data?.interestValue}</td>
                            </tr>
                            <tr>
                              <th>Amount to be disbursed (INR)</th>
                              <td>{data?.placedOffer?.disbursalAmount}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </SideTables>
                  {/* <SideTables className="body-text" style={{ width: "50%" }}>
                    <div className="main-content">
                      <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                        {" "}
                        Repayment Details
                      </h3>
                      <div className="record-details">
                        <table style={{ width: "auto" }}>
                          <tbody>
                            <tr>
                              <th>Repayment Tenure (Days)</th>
                              <td>{data?.term}</td>
                            </tr>
                            <tr>
                              <th>Repayment to ProFinTech (INR)</th>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={data?.value}
                                  thousandsGroupStyle="lakh"
                                  thousandSeparator=","
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Transaction charges (%)</th>
                              <td>
                                {
                                  data?.placedOffer
                                    ?.atTransactionChargesInPercentage
                                }
                                %
                              </td>
                            </tr>
                            <tr>
                              <th>Transaction charge amount (INR)</th>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={
                                    data?.placedOffer?.atTransactionCharges
                                  }
                                  thousandsGroupStyle="lakh"
                                  thousandSeparator=","
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Repayment to finance partner (INR)</th>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={data?.placedOffer?.fpRepaymentAmount}
                                  thousandsGroupStyle="lakh"
                                  thousandSeparator=","
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Balance payment to you (INR)</th>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={data?.placedOffer?.atRepaymentAmount}
                                  thousandsGroupStyle="lakh"
                                  thousandSeparator=","
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </SideTables> */}
                </div>

                <RequestInformation className="body-text">
                  <div className="main-content">
                    <h3
                      style={{
                        marginBottom: "5px",
                        marginTop: "10px",
                        color: "#4A3AFF",
                      }}
                    >
                      Summary
                    </h3>
                    <table style={{ width: "auto" }}>
                      <tbody>
                        <tr>
                          <th>Loan Agreement Signed Date:</th>
                          <td>
                            {" "}
                            <input
                              type="datetime-local"
                              id="loanDate"
                              name="loanDate"
                              value={loanDate}
                              onChange={handleDateChange}
                              required
                              style={{
                                padding: "5px",
                                fontSize: "16px",
                                border: "1px solid rgba(0, 0, 128, 0.2)",
                                borderRadius: "5px",
                                width: "100%",
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Legal Charges:</th>
                          <td>
                            <input
                              type="text"
                              id="legalCharges"
                              name="legalCharges"
                              placeholder="Enter legal charges"
                              value={legalCharges}
                              required
                              onChange={(e) => setLegalCharges(e.target.value)}
                              style={{
                                padding: "5px",
                                fontSize: "16px",
                                border: "1px solid rgba(0, 0, 128, 0.2)",
                                borderRadius: "5px",
                                width: "100%",
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </RequestInformation>
                <div style={{ marginTop: "15px", textAlign: "center" }}>
                  <button
                    style={{
                      borderRadius: "2px",
                      backgroundColor: "#FFF",
                      color: "#4A3AFF",
                      border: "1px solid rgba(0, 0, 128, 0.20)",
                      padding: "10px 20px",
                      cursor: "pointer",
                      borderRadius: "2px",
                      height: "fit-content",
                      boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                      //   opacity: checkBoxValue === true ? "1" : "0.5",
                    }}
                    onClick={() => setShowFullOfferDetails(false)}
                  >
                    Back
                  </button>
                  <button
                    style={{
                      marginLeft: "10px",
                      borderRadius: "2px",
                      backgroundColor: "#4A3AFF",
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                      borderRadius: "2px",
                      height: "fit-content",
                      boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                    }}
                  >
                    Review Offer
                  </button>
                </div>
              </form>
            </>
          )}
          <Dialog
            open={popupOpen}
            onClose={handleClose}
            PaperProps={{
              style: {
                borderRadius: 15,
                padding: "20px",
                maxWidth: "400px",
              },
            }}
          >
            <DialogContent>
              <Stack
                alignItems="center"
                spacing={2}
                justifyContent="center"
                textAlign="center"
              >
                {/* Icon */}
                {isSuccess ? (
                  <CheckCircleIcon sx={{ fontSize: 80, color: "green" }} />
                ) : (
                  <ErrorIcon sx={{ fontSize: 80, color: "red" }} />
                )}

                {/* Message */}
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  color={isSuccess ? "green" : "red"}
                >
                  {isSuccess ? "Success!" : "Failure"}
                </Typography>
                <Typography variant="body1">{apiMessage}</Typography>

                {/* Countdown Timer */}
                {isSuccess && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                    fullWidth
                    sx={{ marginTop: 2 }}
                  >
                    Review Other Accepted Offers
                  </Button>
                )}
              </Stack>
            </DialogContent>

            {/* Close Button */}
            {!isSuccess && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                fullWidth
                sx={{ marginTop: 2 }}
              >
                Close
              </Button>
            )}
          </Dialog>
          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </div>
        {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
      </>
    </>
  );
};

export default RequestOfferReviewFullDetails;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 45% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
    // background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;

const SideTables = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 90% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
    // background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;
