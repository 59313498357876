import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../../context/auth";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useDispatch } from "react-redux";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
} from "@mui/material";
import { Box } from "@mui/system";
import OfferDetails from "./OfferDetails";
import {
  getApiDataAcceptRo,
  getSelectedRequestOffer,
} from "./ActivateRquestOfferWizardSlice";
import TradesARO from "./TradesARO";
import { controllerAPIForROCrtn } from "../../API/FP/Actions";
import { Navigate } from "react-router-dom";

const ActivateRequestOffers = () => {
  const [tabValue, setTabValue] = useState("1");
  const [showtabs, setShowTabs] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useAuth();
  const [requestOfferList, setRequestOfferList] = useState();

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
    setShowTabs(true);
    dispatch(getSelectedRequestOffer(event.target.value));
  };

  window.history.pushState(null, "", window.location.href);
  console.log("setSelectedValue is ", selectedValue);
  const fetchRequestOfferData = async () => {
    setLoading(true);
    try {
      const response = await controllerAPIForROCrtn(
        "post",
        auth?.token,
        " ",
        "fetch-request-offers"
      );
      console.log("apiResp is ", response);

      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        Navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        setRequestOfferList(
          response?.res?.data?.data?.map((i) => i?.requestOffer?.id)
        );
        dispatch(getApiDataAcceptRo(response?.res?.data?.data));
        console.log(
          "apiResp is ",
          response?.res?.data?.data?.map((i) => i?.requestOffer?.id)
        );
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRequestOfferData();
  }, []);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <ContainerStyles>
      <div className="containerP">
        <div>
          {loading ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            </>
          ) : (
            <>
              <h2>Activate Request Offer</h2>
              {showtabs ? (
                <>
                  <p>
                    Review and validate the finance request offer before
                    activation.
                  </p>
                  <TabContext value={tabValue}>
                    <div className="ButtonsComponent">
                      <Box>
                        <TabList
                          onChange={handleTabChange}
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: "#E59C3C",
                            },
                          }}
                        >
                          <Tab
                            label="Offer Details"
                            value="1"
                            style={{
                              textTransform: "none",
                              fontWeight: "600",
                              color: "#4A3AFF",
                            }}
                          />
                          <Tab
                            label="Trades"
                            value="2"
                            style={{
                              textTransform: "none",
                              fontWeight: "600",
                              color: "#4A3AFF",
                            }}
                          />
                        </TabList>
                      </Box>
                    </div>
                    <TabPanel value="1">
                      <OfferDetails setShowTabs={setShowTabs} />
                    </TabPanel>
                    <TabPanel value="2">
                      <TradesARO setShowTabs={setShowTabs} />
                    </TabPanel>
                  </TabContext>
                </>
              ) : (
                <div style={{ width: "500px" }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="dropdown-label"
                      value={selectedValue}
                      onChange={handleDropdownChange}
                      displayEmpty
                      id="SelectRO"
                    >
                      <MenuItem value="" disabled>
                        Select the Request Offer
                      </MenuItem>
                      {requestOfferList?.map((i) => {
                        return <MenuItem value={i}>{i}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </ContainerStyles>
  );
};

export default ActivateRequestOffers;

const ContainerStyles = styled.div`
  .containerP {
    margin: 20px;
  }
  .btn {
    font-size: 15px;
    background-color: #297fff;
    font-family: Open Sans;
    font-weight: 400;
    color: white;
    height: 40px;
    width: 120px;
    border: 0px;
    border-radius: 3px;
    cursor: pointer;
  }
  .btn:hover {
    background-color: #0056b3;
  }
  .ButtonsComponent {
    display: flex;
    gap: 10px;
  }
`;
