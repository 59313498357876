import React, { useState } from "react";
import styled from "styled-components";

const VAGenerationWizard = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [finaceDropDownChange, setFinanceDropDownChange] = useState("");
  const [selectedFinanceRequest, setSelectedFinanceRequest] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [selectedPersona, setSelectedPersona] = useState("");
  const [selectedAnchorTrader, setSelectedAnchorTrader] = useState("");
  const [selectedFinancePartner, setSelectedFinancePartner] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedFinanceRequest(""); // Reset the request selection when the main option changes
    setSelectedTransaction(""); // Reset the transaction selection when the main option changes
    setSelectedPersona(""); // Reset the persona selection when the main option changes
    setSelectedAnchorTrader("");
    setSelectedFinancePartner("");
    setFinanceDropDownChange("");
  };

  const handleFinanceRequestChange = (event) => {
    setSelectedFinanceRequest(event.target.value);
    setFinanceDropDownChange("request1");
    setSelectedTransaction(""); // Reset the transaction selection when the request changes
  };

  const handleTransactionChange = (event) => {
    setSelectedTransaction(event.target.value);
  };

  const handlePersonaChange = (event) => {
    setSelectedPersona(event.target.value);
    setSelectedAnchorTrader(""); // Reset the anchor trader selection when the persona changes
    setSelectedFinancePartner(""); // Reset the finance partner selection when the persona changes
  };

  const handleAnchorTraderChange = (event) => {
    setSelectedAnchorTrader(event.target.value);
  };

  const handleFinancePartnerChange = (event) => {
    setSelectedFinancePartner(event.target.value);
  };

  return (
    <Container>
      <Header>Virtual Account Generation Wizard</Header>
      <Paragraph>
        Welcome to the Virtual Account Generation Wizard. Follow the steps to
        generate your virtual account.
      </Paragraph>
      <DropdownContainer>
        <Label>Please Select the category</Label>
        <Select value={selectedOption} onChange={handleSelectChange}>
          <option value="">Select an option</option>
          <option value="sticky">Sticky Virtual Account</option>
          <option value="transactional">Transactional Virtual Account</option>
        </Select>
      </DropdownContainer>
      {selectedOption === "transactional" && (
        <DropdownContainer>
          <Label>Finance Request</Label>
          <Select
            value={selectedFinanceRequest}
            onChange={handleFinanceRequestChange}
          >
            <option value="">Select a finance request</option>
            <option value="request1">Request 1</option>
            <option value="request2">Request 2</option>
          </Select>
        </DropdownContainer>
      )}
      {selectedOption === "transactional" &&
        finaceDropDownChange === "request1" && (
          <DropdownContainer>
            <Label>Select the transaction options</Label>
            <Select
              value={selectedTransaction}
              onChange={handleTransactionChange}
            >
              <option value="">Select an option</option>
              <option value="disbursement">Disbursement</option>
              <option value="repayment">Repayment</option>
            </Select>
          </DropdownContainer>
        )}
      {selectedTransaction && <Button>Proceed</Button>}
      {selectedOption === "sticky" && (
        <DropdownContainer>
          <Label>Please select the persona</Label>
          <Select value={selectedPersona} onChange={handlePersonaChange}>
            <option value="">Select a persona</option>
            <option value="1">Anchor Trader</option>
            <option value="3">Finance Partner</option>
          </Select>
        </DropdownContainer>
      )}
      {selectedPersona === "1" && (
        <>
          <DropdownContainer>
            <Label>Select the Anchor Trader by ID</Label>
            <Select
              value={selectedAnchorTrader}
              onChange={handleAnchorTraderChange}
            >
              <option value="">Select an ID</option>
              <option value="anchor1">Anchor Trader 1</option>
              <option value="anchor2">Anchor Trader 2</option>
            </Select>
          </DropdownContainer>
          <DropdownContainer>
            <Label>Select the Finance Partner by ID</Label>
            <Select
              value={selectedFinancePartner}
              onChange={handleFinancePartnerChange}
            >
              <option value="">Select an ID</option>
              <option value="finance1">Finance Partner 1</option>
              <option value="finance2">Finance Partner 2</option>
            </Select>
          </DropdownContainer>
          {selectedAnchorTrader && selectedFinancePartner && (
            <Button>Proceed</Button>
          )}
        </>
      )}
      {selectedPersona === "3" && (
        <DropdownContainer>
          <Label>Select the Finance Partner by ID</Label>
          <Select
            value={selectedFinancePartner}
            onChange={handleFinancePartnerChange}
          >
            <option value="">Select an ID</option>
            <option value="finance1">Finance Partner 1</option>
            <option value="finance2">Finance Partner 2</option>
          </Select>
        </DropdownContainer>
      )}
    </Container>
  );
};

export default VAGenerationWizard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
  font-family: Arial, sans-serif;
`;

const Header = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  max-width: 600px;
`;

const DropdownContainer = styled.div`
  margin: 1rem 0;
  width: 100%;
  max-width: 600px;
`;

const Label = styled.label`
  font-size: 1.3rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
