import { createSlice } from "@reduxjs/toolkit";

import React from "react";

const DisbursmentWizardSlice = createSlice({
  name: "Disbursment Slice",

  initialState: {
    disbursmentID: 0,
  },

  reducers: {
    getDisbursmentId(state, action) {
      state.disbursmentID = action.payload;
    },
  },
});

export const { getDisbursmentId } = DisbursmentWizardSlice.actions;

export default DisbursmentWizardSlice.reducer;
