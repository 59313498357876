import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import "./ManageFinanceRequest.css";

const ManageFinanceRequest = () => {
  const location = useLocation();
  const selectedRequest = location.state;
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("financeRequest");

  const navigateBack = () => {
    navigate("/creditbazaar/Receivables");
  };

  return (
    <div className="parent">
      <div className="content">
        <p className="title">Manage Finance Request</p>
        <p className="finance-id">
          Finance Request ID:{" "}
          {selectedRequest?.financeRequestID || "No Request Selected"}
        </p>
        <div className="tabs">
          <button
            onClick={() => setActiveSection("financeRequest")}
            className={`tab-button ${
              activeSection === "financeRequest" ? "active" : ""
            }`}
          >
            Finance Request Details
          </button>
          <button
            onClick={() => setActiveSection("questionnaire")}
            className={`tab-button ${
              activeSection === "questionnaire" ? "active" : ""
            }`}
          >
            Questionnaire
          </button>
        </div>
        <div className="tab-content">
          {activeSection === "financeRequest" && (
            <div>
              <div>
                <p className="headerText">Finance Request Details</p>
                <div className="flex">
                  <div>
                    <p className="smallText">Finance Request ID</p>
                    <input type="text" className="input" />
                  </div>
                  <div>
                    <p className="smallText">Client Name</p>
                    <input type="text" className="input" />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p className="smallText">Organisation Name</p>
                    <input type="text" className="input" />
                  </div>
                  <div>
                    <p className="smallText">Loan Amount</p>
                    <input type="text" className="input" />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p className="smallText">Offer Accepted Date</p>
                    <input type="date" className="input" />
                  </div>
                </div>
              </div>
              <div>
                <p className="headerText">Disbursement Details</p>
                <div className="flex">
                  <div>
                    <p className="smallText">Disbursement ID</p>
                    <input type="text" className="input" />
                  </div>
                  <div>
                    <p className="smallText">Amount to be Disbursed</p>
                    <input type="text" className="input" />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p className="smallText">Disbursement Date</p>
                    <input type="date" className="input" />
                  </div>
                </div>
              </div>
              <div>
                <p className="headerText">Repayment Details</p>
                <div className="flex">
                  <div>
                    <p className="smallText">Repayment ID</p>
                    <input type="text" className="input" />
                  </div>
                  <div>
                    <p className="smallText">Total Amount Repaid</p>
                    <input type="text" className="input" />
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <p className="smallText">Repayment Due Date</p>
                    <input type="date" className="input" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeSection === "questionnaire" && (
            <div>
              <h1>Hi</h1>
              <h2>Hello</h2>
            </div>
          )}
        </div>
      </div>
      <button className="back" onClick={navigateBack}>
        Back
      </button>
    </div>
  );
};

export default ManageFinanceRequest;
