import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useAuth } from "../../../context/auth";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material"; // Assuming you're using MUI components for the popup
import { controllerObjectForCAMReport } from "../../Common/Constants";
import { controllerAPI } from "../../API/FP/Actions";
import { Navigate } from "react-router-dom";
import view from "../../images/view.png";
const TradesARO = ({ setShowTabs }) => {
  const [loadig, setisLoading] = useState(false);
  const [auth, setAuth] = useAuth();
  const [openPopup, setOpenPopup] = useState(false);
  const [camReportUrl, setCamReportUrl] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const data = useSelector(
    (state) => state?.activateRquestOfferWizardReducer?.data || []
  );

  const selectedRequestOffer = useSelector(
    (state) => state?.activateRquestOfferWizardReducer?.selectedRo
  );

  const tradeDetails = data.filter(
    (i) => i?.requestOffer?.id === selectedRequestOffer
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const handleCamReport = async (url) => {
    setisLoading(true);
    console.log("The url is:", url);

    try {
      const controllerOject = controllerObjectForCAMReport(authContextObj, url);
      const apiresp = await controllerAPI(
        "post",
        auth?.token,
        controllerOject,
        "get-presigned-url-cam-reports"
      );
      if (apiresp?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        Navigate("/creditbazaar/");
      }
      if (apiresp?.res?.status === 200) {
        handleOpenPopup(apiresp?.res?.data?.data?.signed_url);
        setOpenSnackbar(true);
        setApiResultMessage("CAM Report generated successfully !");
        setAlertSeverity("success");
      }
      console.log("the apis response is :", apiresp);
    } catch (e) {
      console.log("Error is :", e);
      setOpenSnackbar(true);
      setApiResultMessage("Error in generating CAM Report!");
      setAlertSeverity("error");
      setisLoading(false);
    } finally {
      setisLoading(false);
    }
  };
  // Extracting trade data for table

  const handleOpenPopup = (url) => {
    setCamReportUrl(url);
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const tableHeaderNames = [
    "ID",
    "Invoice Date",
    "Invoice Number",
    "Anchor Trader",
    "Trade Partner",
    "Trade Amount (INR)",
    "Term (Days)",
    "CAM Report",
  ];

  return (
    <div>
      <RequestInformation>
        <div className="main-content">
          <div className="record-details">
            {loadig ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress />
                </div>
              </>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      {tableHeaderNames.map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      .filter(
                        (i) => i?.requestOffer?.id === selectedRequestOffer
                      )
                      .map((i) =>
                        i?.financeRequestTradesMaps?.map((trade, index) => (
                          <tr key={index}>
                            <td>{trade?.trademaster?.id}</td> {/* ID Column */}
                            <td>{trade?.trademaster?.tradeDate}</td>
                            <td>{trade?.trademaster?.tradeId}</td>
                            <td>{trade?.trademaster?.sourceName}</td>
                            <td>{trade?.trademaster?.targetName}</td>
                            <td>{trade?.trademaster?.tradeAmount}</td>
                            <td>{trade?.trademaster?.tradeTerms}</td>
                            <td>
                              <Tooltip
                                title={
                                  trade?.camLink
                                    ? "View Cam Report"
                                    : "No Cam Report Available"
                                }
                                arrow
                              >
                                <button
                                  style={{
                                    color: "black",
                                    height: "45px",
                                    width: "120px",
                                    backgroundColor: "white",
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "10px 20px",
                                    borderRadius: "3px",
                                    cursor: trade?.camLink
                                      ? "pointer"
                                      : "not-allowed",
                                    opacity: trade?.camLink ? "1" : "0.5",
                                  }}
                                  disabled={!trade?.camLink}
                                  onClick={() =>
                                    handleCamReport(trade?.camLink)
                                  }
                                >
                                  <img
                                    src={view}
                                    alt="View Icon"
                                    style={{
                                      height: "24px",
                                      width: "24px",
                                      opacity: trade?.camLink ? "1" : "0.5",
                                    }}
                                  />
                                </button>
                              </Tooltip>
                            </td>
                          </tr>
                        ))
                      )}
                  </tbody>
                </table>

                <button
                  className="Backbtn"
                  style={{
                    marginTop: "10px",
                    color: "black",
                    height: "45px",
                    width: "120px",
                    backgroundColor: "white",
                    border: "1px solid",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px 36px",
                    borderRadius: "3px",
                    cursor: "pointer ",
                    alignSelf: "flexStart",
                  }}
                  onClick={() => setShowTabs(false)}
                >
                  Back
                </button>
              </>
            )}
          </div>
        </div>
      </RequestInformation>

      {/* Popup for CAM Report */}

      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        maxWidth="md" // Sets the maximum width (e.g., 'xs', 'sm', 'md', 'lg', 'xl')
        fullWidth // Makes it take the full width up to maxWidth
      >
        <DialogTitle>Cam Report</DialogTitle>
        <DialogContent
          sx={{
            padding: 0, // Removes default padding to make the iframe fill the space
          }}
        >
          {/* Iframe for Cam Report */}
          <Box
            component="iframe"
            src={camReportUrl} // Dynamic URL
            title="Embedded Form"
            sx={{
              width: "100%",
              height: "60vh", // Height of iframe
              border: "none",
              borderRadius: "4px",
            }}
          ></Box>
        </DialogContent>

        {/* Close Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleClosePopup}
            sx={{
              textTransform: "none", // Avoid uppercase text
            }}
          >
            Close
          </Button>
        </Box>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        //autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TradesARO;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }

  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }

  th {
    color: rgba(97, 94, 131, 1);
  }

  .main-content {
    margin-top: 30px;
  }
`;
