import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getIndividualAssessmentData,
  getSelectedFRData,
} from "./IndividualAssessmentListSlice";
import { useAuth } from "../../../context/auth";
import {
  controllerAPI,
  controllerAPIForROCrtn,
  opscontroller,
  setdisbursmentOPS,
} from "../../API/FP/Actions";
import Pagenotfound from "../../Common/pagenotfound";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Divider,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  contextObjforapicall,
  contextObjforapicallStartWizard,
  CreateRequestOffer,
  objectforOPSsetdisb,
  objectForTheIndividualAPiCall,
} from "../../Common/Constants";

const Step1 = ({ nextStep }) => {
  const steps = ["Step 1 ", "Step 2", "Step 3"];
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const dispatch = useDispatch();
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };
  const [selectedFR, setSelectedFR] = useState("");
  const [financeReqList, setFinanceReqList] = useState([]);

  const handleOnClickIndividual = (e) => {
    e.preventDefault();
    apiCallForIndividualAssesments(selectedFR);
  };

  const apiCallForIndividualAssesments = async (id) => {
    dispatch(getSelectedFRData(selectedFR));
    setLoading(true);
    try {
      const constructObj = objectForTheIndividualAPiCall(authContextObj, id);
      const AtRespReq = await controllerAPIForROCrtn(
        "post",
        auth?.token,
        constructObj,
        "get-individual-assessments"
      );

      if (AtRespReq?.res?.status === 200) {
        setLoading(false);
        dispatch(getIndividualAssessmentData(AtRespReq?.res?.data?.data));
        nextStep();
      }
      if (AtRespReq?.res?.status === 400) {
        setLoading(false);
        dispatch(getIndividualAssessmentData(" "));
        nextStep();
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const getRequestOfferData = async () => {
    setLoading(true);
    try {
      const response = await opscontroller(
        "post",
        auth?.token,
        null,
        "get-finance-requests"
      );

      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        console.log("ids ", response?.res?.data?.data);
        setFinanceReqList(response.res.data.data);
      }
    } catch (error) {
      console.error("Error fetching accepted offer details:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRequestOfferData();
  }, [auth?.token]);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              width: "100%",
            }}
          >
            <label
              style={{ fontSize: "16px", color: "#555", fontWeight: "500" }}
            >
              Select Finance Request ID:
            </label>
            <select
              style={{
                width: "100%",
                height: "45px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                padding: "0 10px",
                fontSize: "16px",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
              id="selectFRID"
              onChange={(e) => setSelectedFR(e.target.value)}
            >
              <option value="">Select Finance Request</option>
              {financeReqList?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.id}
                </option>
              ))}
            </select>
          </div>

          {selectedFR && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {" "}
              <button
                className="nextBtn"
                style={{
                  fontSize: "18px",
                  backgroundColor: "#297fff",
                  fontFamily: "Open Sans",
                  fontWeight: 400 /* Remove quotes */,
                  color: " white",
                  height: "45px",
                  width: "120px",
                  border: "none",
                  borderRadius: "2px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={(e) => handleOnClickIndividual(e)}
              >
                Next
              </button>
            </div>
          )}
          <Snackbar
            open={openSnackbar}
            //autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {apiResultMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
};

export default Step1;
