import React from "react";
import { createSlice } from "@reduxjs/toolkit";

const IndividualAssessmentListSlice = createSlice({
  name: "Individual Assessment list data ",
  initialState: {
    data: null,
    state: "idle",
    selectedFinanceRequest: null,
  },

  reducers: {
    getIndividualAssessmentData: (state, action) => {
      state.data = action.payload;
    },
    getSelectedFRData: (state, action) => {
      state.selectedFinanceRequest = action.payload;
    },
  },
});

export const { getIndividualAssessmentData, getSelectedFRData } =
  IndividualAssessmentListSlice.actions;

export default IndividualAssessmentListSlice.reducer;
