import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage
import apiResponseReducer from "./components/AnchorTrader/ApiResponseSlice";
import DisbursmentWizardReducer from "./components/Reducers/DisbursmentWizardReducer";
import IndividualAssessmentListSlice from "./components/WizardUi/RequestOffer/IndividualAssessmentListSlice";
import ActivateRquestOfferWizardSlice from "./components/WizardUi/Activate Request Offers/ActivateRquestOfferWizardSlice";
import DisbursmentWizardSlice from "././components/WizardUi/DisbursmentWizard/DisbursmentWizardSlice";
import SettlementWizardSlice from "././components/WizardUi/SettlementWizard/SettlementWizardSlice";
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, apiResponseReducer);

export const store = configureStore({
  reducer: {
    apiResponse: persistedReducer,
    wizardReducser: DisbursmentWizardReducer,
    IndividualAssessmentReducer: IndividualAssessmentListSlice,
    activateRquestOfferWizardReducer: ActivateRquestOfferWizardSlice,
    DisbursmentWizardSlice: DisbursmentWizardSlice,
    SettlementWizardSlice: SettlementWizardSlice,
  },
});

export const persistor = persistStore(store);
