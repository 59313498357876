import React, { useEffect, useState } from "react";
import {
  Alert,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  Snackbar,
  TextField,
  Typography,
  IconButton,
  Divider,
  Step,
  StepLabel,
} from "@mui/material";
import { useAuth } from "../../../context/auth";
import { Box, styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import CloseIcon from "@mui/icons-material/Close";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Component1 from "./Component1";
import Component4 from "./Component4";
import Component3 from "./Component3";
import Component2 from "./Component2";
import { tradeDocReaderGetATController } from "../../Common/Constants";
import { controllerAPI, controllerAPICall } from "../../API/FP/Actions";
import Component5 from "./Component5";
import Componentpage2 from "./Componentpage2";

const Page2 = ({ setTabActive }) => {
  setTabActive(true);
  const [files, setFiles] = useState();
  const steps = [
    "Input Details ",
    "Select trades",
    "Review trades ",
    "Trade doc reader",
    "Verify & Update",
  ];
  // const files = [
  //   { filename: "file1.txt", filesize: "15 KB", uploadDate: "2024-11-01" },
  //   { filename: "file2.pdf", filesize: "45 KB", uploadDate: "2024-11-02" },
  //   { filename: "file3.jpg", filesize: "120 KB", uploadDate: "2024-11-03" },
  // ];
  const [step, setStep] = useState(1);

  const [activeStep, setActiveStep] = useState(0);
  const [auth, setAuth] = useAuth();
  const [selectedAnchorTrader, setSelectedAnchorTrader] = useState();
  const [s3Paths, setS3Paths] = useState([]);
  const [selectedS3Path, setSelectedS3Path] = useState("");
  const [tpGSTID, setTPGSTID] = useState();
  const [list, setList] = useState([]);
  const [nextClicked, setNextClicked] = useState(false);
  //const [files, setFiles] = useState([]);
  const [selectedFilesName, setSelectedFilesName] = useState([]);
  const [showNextWizard, setShowNextWizard] = useState(false);
  const [selectedFiles, setSelectedFilesState] = useState([]);
  const [showpageTwo, setshowpageTwo] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [isctive, setisActive] = useState(false);
  const [reviewClicked, setReviewClicked] = useState(false);
  const navigate = useNavigate();
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState();
  const [component1Output, setComponent1OutPut] = useState();
  const [tradeMasterDocReader, setTradeMasterDocReader] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  useEffect(() => {
    getATDetails();
  }, []);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const handleCheckboxChange = (file) => {
    setSelectedFilesName((prevSelectedFiles) => {
      // Determine if the file is already selected
      const updatedSelectedFiles = prevSelectedFiles.find(
        (f) => f.fileName === file.fileName
      )
        ? prevSelectedFiles.filter((f) => f.fileName !== file.fileName)
        : [...prevSelectedFiles, file];

      // Update `isActive` if there is at least one file selected
      setisActive(updatedSelectedFiles.length > 0);

      return updatedSelectedFiles;
    });
  };

  const getATDetails = async () => {
    setIsLoading(true);
    try {
      const constructObj = tradeDocReaderGetATController(21);
      const response = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "get_AnchorTrader_dataconnector_details"
      );
      if (response?.response?.status === 401) {
        localStorage.removeItem("auth");
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        navigate("/creditbazaar/");
      }
      if (response.res.status === 200) {
        console.log(
          "Get AT details are successfull",
          response?.res?.data.map((item, index) => {
            return item?.channelPartnerName;
          })
        );

        setList(
          response?.res?.data.map((item, index) => {
            return { AT: item?.channelPartnerName, S3: item?.s3path };
          })
        );
        setSelectedAnchorTrader(
          response?.res?.data.map((item, index) => {
            return item?.channelPartnerName;
          })
        );
      }
    } catch (error) {
      console.log("error is :", error);
      setOpenSnackbar(true);
      setApiResultMessage("Failed to fetch the details !");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
    setActiveStep((prevStep) => prevStep - 1);
  };

  window.history.pushState(null, "", window.location.href);
  const goBack = () => {
    setStep((prevStep) => prevStep - 2);
    setActiveStep((prevStep) => prevStep - 2);
  };
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Componentpage2
            nextStep={nextStep}
            setShowTable={setShowTable}
            selectedAnchorTrader={selectedAnchorTrader}
            list={list}
            setIsLoading={setIsLoading}
            setComponent1OutPut={setComponent1OutPut}
            setFiles={setFiles}
          />
        );

      case 2:
        return (
          <Component2
            nextStep={nextStep}
            handleCheckboxChange={handleCheckboxChange}
            files={files}
            selectedFilesName={selectedFilesName}
            isctive={isctive}
            prevStep={prevStep}
            setIsLoading={setIsLoading}
            component1Output={component1Output}
          />
        );
      case 3:
        return (
          <Component3
            nextStep={nextStep}
            selectedFilesName={selectedFilesName}
            isctive={isctive}
            prevStep={prevStep}
            setIsLoading={setIsLoading}
            component1Output={component1Output}
            setTradeMasterDocReader={setTradeMasterDocReader}
            setPdfUrl={setPdfUrl}
          />
        );
      case 4:
        return (
          <Component4
            isctive={isctive}
            nextStep={nextStep}
            prevStep={prevStep}
            setIsLoading={setIsLoading}
            tradeMasterDocReader={tradeMasterDocReader}
            setTPGSTID={setTPGSTID}
            pdfUrl={pdfUrl}
          />
        );
      case 5:
        return (
          <Component5
            isctive={isctive}
            nextStep={nextStep}
            prevStep={prevStep}
            setIsLoading={setIsLoading}
            tpGSTID={tpGSTID}
            isLoading={isLoading}
            tradeMasterDocReader={tradeMasterDocReader}
            goBack={goBack}
          />
        );
      default:
        return null;
    }
  };
  const [firstComponetOutput, setFirstComponentOutput] = useState();
  const [secondComponetOutput, setSecondComponentOutput] = useState();
  const [thirdComponetOutput, setThirdComponentOutput] = useState();
  const [fourthComponetOutput, setFourthComponentOutput] = useState();
  const [fifthComponetOutput, setFifthComponentOutput] = useState();

  return (
    <div style={{ marginTop: "100px", margin: "100px 100px 100px 100px" }}>
      <Stepper activeStep={step - 1} style={{ margin: "0px 200px " }}>
        {steps.map((label, index) => (
          <Step key={index}>
            <div
              style={{
                opacity: activeStep === index ? "1" : "0.5",
                color: "black",
                fontWeight: "500",
              }}
            >
              <StepLabel className="StepLabel">{label}</StepLabel>
            </div>
          </Step>
        ))}
      </Stepper>
      {isLoading ? (
        <CircularProgress
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            zIndex: "1100",
          }}
        />
      ) : (
        <div className="main-content" style={{ marginLeft: "200px" }}>
          {renderStep()}
        </div>
      )}
      <Snackbar
        open={openSnackbar}
        //autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Page2;
